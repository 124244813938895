import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
import CallToAction from '../../components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Content Warning: This blog post contains gifs.`}</em></p>

    <p>{`I’ve seen a ton of designers make these GORGEOUS checkbox styles, but then you see them implemented and you can’t even select it using your keyboard. Let’s say we got this in our style guide from our designer.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "502px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.24324324324324%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB/0lEQVQ4y4VSXY/SUBDlV/ov/BNrDBgCGze6WRIXWz4T4waKBCityBJaWqAIFMrn0z4vhBe7xpfjzNUSDLA+nMzHvffcOTMTms1mmM/neyyXSywWC3Ce7R/MheVzttPpFOPxGK7rHiGkaRpqNRWarkGt15HPZaGUvkBVa7i7+4w65UoUFxVF+KqqwrJtTCaTk6ShTDqNcCSCxNUVrgmvX10gFr9EKiUjHo8LxGKXiLyJIBwOIxqNolKpUrUzjEajY0KPpC1Jylu7h5u2KWSxJJbO/mq1wnq93ksOzs9K5l88uvAi/wkvyxU0m00kJQmyLEOS00gkbpDN5jEYfBckfD8gOyk5OHjYbPDwuEG320Wj8Y3QQKvVQuv+HoZhYHRAEuAw/oeQf/319ISfvi8qlKhCHkiOBvQxmYSm6WLq54gO/X2F3CdGv99Hu91Gp9OBZVnCtyx7/5CnOxwOhT016T1hsHcd0xSrUSwUUC6XoSglYWvVKq1PiWIFBTorFIu0ajrcv6RHFe52O2y3W/R6Pdi0ZwPHgUnkhmGKvnLeoRwrYHCO45OSuYc+9c/3f0DXv0KWZKQzWWRzGdHL6/fvcHv7AVIqDZNa4Xne/yUfXuDfuYfOwKEqqKc0Za7apjz3z31m0qFTSSbm5Q0+4Yo4npJ/bv+OKgwSh3t2Ds+R/gZkWqvP/geNwAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Checkboxes design with teal color when checked and a black checkmark",
          "title": "Checkboxes design with teal color when checked and a black checkmark",
          "src": "/static/9e42a8bdd05057fd3901bc435050a03c/eea79/teal-checkboxes-design.png",
          "srcSet": ["/static/9e42a8bdd05057fd3901bc435050a03c/12f09/teal-checkboxes-design.png 148w", "/static/9e42a8bdd05057fd3901bc435050a03c/e4a3f/teal-checkboxes-design.png 295w", "/static/9e42a8bdd05057fd3901bc435050a03c/eea79/teal-checkboxes-design.png 502w"],
          "sizes": "(max-width: 502px) 100vw, 502px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I’ve seen this implemented before and it looks gorgeous. However, when I press the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tab`}</code>{` key, it zips right past it. If this field is required, you’re screwing over a bunch of your users. They use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::before`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::after`}</code>{` pseudo-elements to make a pretty checkbox and use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:checked`}</code>{` pseudo-class to determine the styling of the check itself. It looks cool, but the problem is that they use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`display: none`}</code>{` on the checkbox input itself. When we do that, we make the checkbox itself invisible to the browser, making it unusable for those who rely on keyboards to navigate a site.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/3Fd7rUatwPzyAloC5O/giphy.gif",
        "alt": "Gif of keyboard trying to access the custom checkmarks but skipping to the link."
      }}></img></p>
    <CallToAction mdxType="CallToAction">
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "160.13513513513513%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAACXBIWXMAABYlAAAWJQFJUiTwAAAD0ElEQVRIx61Wa1caVxTl56T/s/ZTl9/apmu1qcmqpKgFjYloRKRgFIMoohJQQd4vB+QNAzPsnnOGl4qmZeWuddedM/fOnvPYZ8+YYi/noHjsKHy0ouiwzThXUdi2If7HjzAx2LcaDGwqbFvF0NUO9F4Xerd7fx3MvtYb25N7fN1V0e/3kbdbCJBcFkDafG7wA18befsShexaR1mpIhk4h5LMoJTKolGuIH8VQ/EmiVa1JmuC9iv5ouwVonGZ9dIdbuMpFGMJaJqOwtYKTCX3B6QicSRPz3Fqd8L1uxlHq3Y4fl7Ap8W/ce5wI+YLIBu+kvsHf9nwz6u38Nk2xHa9MsP56xuU0jkoTsphcWdV3K3k8mjV6uh2VKjNFpqVGtq1Btkd2a8pJfLuDo27Ctr1BjqNpqw8W3TW8HB5nMPhMMK9ga7rYleLCr2s8ChfHH6zUn2cw+IAUFNVWeMnZxLukc0O/9omfNYP2Hn5mmwjxIO3NhyurMO7tCa5k4JqmgG4OQGo93qychEyXy6RDIaQu4xK7rhAfJ0OXSJ19gW3iZSc4bPCgEE09wE1A1Drac9SgyurdXt0rjcCG1LqPmDX4GFk7xCBDYd4eO31I+zxIuw+QHBrF1dk71PIHL7PukFUunjaQ+kEykU2fI3jd1uIfj4WgIjnEDf+oLyEwdkOu70I2HfkpU8D6poANokWD8cw6U920TRAegqxowBi/lNc7HioCDHxgAty8n5bCJ4+D0txeC9F18nTEMpE6BFtHgJe7vuQoUoO6eKn0Lnl3K8tksN9s1WAj9c/4vPKe4R29yTnUwE5ZK5cvVRGT+2i2+6gR4XiTuBr7h4OnVe+r7bbxhk6i6lVHtBgljGVNlxl3ph1TgHUxkTV+yPCGrY+tkf3+vfuTSX2UBD+d8iTtBl+AhLHQakyi6mSTCMXiYpEsYAqibQIARP8wunBHakPC2y1cEsNcCLXPHIbljFglLoi5PokHcG82/1tUdSbW+1w+R08b5ZoLiO46YSX6BPc3IV7wSKCzNwcAw5C7hA9eiRhTAWmx7D5OQ3cjtyGTOxiJot0PIEKCS6rDesnP/NIDzHIAwNlI9coUIh1pSyhqq021IFefv/DHEwmE+ZolfxNCuxDHnLleHA4F849eC1rcFHojl8WcECCmjsL40/zIr578QLz8/OGAxTNszzkwdLOusfecd8ahcqgfluCUiohHKaezuVGpH4S8L9+f+91yHOAw41JAutTiK0PVXp6p6yOczhr642IzZ/Rbds3+1nKMw/5F4y95B8ddnm2uSxgKfNP+Bdm0kH4LeIgSQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Create custom keyboard accessible checkboxes. Free Egghead Video",
            "title": "Create custom keyboard accessible checkboxes. Free Egghead Video",
            "src": "/static/4ec33f96b09922342eb3a661950ae7dd/0b533/egghead.png",
            "srcSet": ["/static/4ec33f96b09922342eb3a661950ae7dd/12f09/egghead.png 148w", "/static/4ec33f96b09922342eb3a661950ae7dd/e4a3f/egghead.png 295w", "/static/4ec33f96b09922342eb3a661950ae7dd/0b533/egghead.png 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <h2>{`Want to see this in action?`}</h2>
      <p>{`  I created a less than 3 minute video lesson!`}</p>
      <p>{`  `}<a parentName="p" {...{
          "href": "https://egghead.io/lessons/css-create-custom-keyboard-accessible-checkboxes"
        }}>{`Go To My Free Egghead Lesson`}</a></p>
    </CallToAction>
    <h2>{`Starting point`}</h2>
    <p>{`Let’s walk step by step how I would go through this. Here is what my starting code looks like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`fieldset`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`legend`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Accessible Checkboxes`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`legend`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`check_1`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`check_1`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Checkbox`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`CSS Only`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`css_only`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`css_only`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`CSS Only`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`disabled_sample`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`disabled`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`disabled_sample`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`A disabled checkbox`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Fourth Option`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`fourth_check`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`fourth_check`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Fourth Option`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`fieldset`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.54054054054055%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABIUlEQVQ4y82SSW6EMBBF+/4HYbCYUTOEJgixACGlg5hBgiXX+LEdgQhJh15kkcWXXXbVc/2CS1VVKMsSbB2GAX3f8/W4H8eRxyx3zf9Jl/WyKN7hOA48z4dpGlQmXBrbts33lmXB9/2t8BF0ByygaRpkmUASBUiSDMPQaSxziaIIVdXOgdtlXWNZFoThK+9K13VcHRdt2262u647t7x/bZombpsQQrtREQQBBzZNw1cmDnwA+wJkYh0EwQs0RYUgCFAVBeHtRmdoQaKjuNLH9vmnHc7zDI8OntBiQmQoqsJn53oesixDmqafhc8CmaU8zxHHMQWkSJIEURTh7X7n3TPrp5ZX2Gqjph9nPzcGYmd7m09ZPiYf9duv8g34l/r/wA/RLHXW3uFs/wAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Basic checkboxes with no design.",
          "title": "Basic checkboxes with no design.",
          "src": "/static/e7d8617e0b896100dfc7cdd0651df4bb/fcda8/Starting-point.png",
          "srcSet": ["/static/e7d8617e0b896100dfc7cdd0651df4bb/12f09/Starting-point.png 148w", "/static/e7d8617e0b896100dfc7cdd0651df4bb/e4a3f/Starting-point.png 295w", "/static/e7d8617e0b896100dfc7cdd0651df4bb/fcda8/Starting-point.png 590w", "/static/e7d8617e0b896100dfc7cdd0651df4bb/57dc1/Starting-point.png 718w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I would start with a bare-bones checkbox list. Here is the current CSS I have:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox']`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox'] + label`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 1.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Create a pseudo-element on the label`}</h2>
    <p>{`The first thing I want to do is make sure that I create a pseudo-element that can act in place of my checkbox. What I’ll do to achieve this is create a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::before`}</code>{` pseudo-element on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<label>`}</code>{` element. Now it looks like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox'] + label::before`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-right`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 10px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "556px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "59.45945945945946%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABx0lEQVQoz4WTW0/CQBCF+e8qiVcS5U2jJmBbFUmromKw5aJAIRCgV6BySfgLPBAejjujRUXUJpNuZqdfz5zZjViWhXa7Dc/z4Ps+r8NoNptwXZfXtm3zvuM46HQ6oO9WRYQK4vE4YrEYDvb3Ed1Yw/r6Bo4OD7G3tysihs3NLezsbCMajSKRSMIVPyfoKnCEko1GA9VqlVXUajVUKhXO1et1mGZN7Jkov7ygWCxxnWXZfwOHwyEmkwnoeX0NICkK0uk0kmcykskEHh6ybAm1/2/LtEneBEGA6XSKbtdHPp+HruswDAO5XA7lcpkh5GWo7FPhd6UM7PV6GAwGmM1mDJQkCReXl0LlFWRJhilsoLzjuKzS896VLsMWQPKOFM7nc/Fhl1UZuoFCoSDavRfeFVESwe/SMwzRgWmaC9gPhQTs9/vcsuPYuL25gZpWoQgvJekMmnbNOUVWICvnOD45haqqC9g3IPlCQxmPxzyUIOhDFYBMJgPtWmOFqVSKAbrw1BN+h8NZVsfAcEFFo9GIC7PZRwYSwNCfhEINd/d3Ykh5PjJfh7Oy5TBarRbCqZOXfFQ+bpD/oWwZ8qPlVbKXj0h4FX+DfI03jPMprib/36YAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Checkboxes with both a white box and a normal checkbox.",
          "title": "Checkboxes with both a white box and a normal checkbox.",
          "src": "/static/5e8102fb292c6fc15a922c4e77f2d983/96638/checkboxes-with-psuedo.png",
          "srcSet": ["/static/5e8102fb292c6fc15a922c4e77f2d983/12f09/checkboxes-with-psuedo.png 148w", "/static/5e8102fb292c6fc15a922c4e77f2d983/e4a3f/checkboxes-with-psuedo.png 295w", "/static/5e8102fb292c6fc15a922c4e77f2d983/96638/checkboxes-with-psuedo.png 556w"],
          "sizes": "(max-width: 556px) 100vw, 556px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I’ve left the non-styled original checkbox there on purpose. The reason for this is it makes it easier for me to tell when a checkbox is focused, checked, etc. It helps me to hold off on hiding the checkbox until the very last minute.`}</p>
    <h2>{`Add styling on the pseudo-element when checked`}</h2>
    <p>{`As of right now, when we try to check the checkbox, it doesn’t do anything except the normal behavior. What we have to do is add a little bit of CSS magic using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:checked`}</code>{` pseudo-class. See Below:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox']:checked + label::before`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #5ac5c9`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "504px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.810810810810814%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACC0lEQVQoz3VT227aQBTMp/Yv+hGphAo0acPF3KHGdiE0PBFsjF0IBBXwNdzpSx95pUFoenYbUwgt0ujsmt3xnJnjC9tx0Ov1UJYk3NXrqFQqKJWKyOVyqN5WUS6XIckyZEKxWESt9hUSndV1HbZtYzwec1iWxXFh2w4e+w9IfAzjKvoBnyIRqlFEqAqCgHw+j3A4guura4RCIUTpzLvLS9wSseO654SONULnu4eb1gY16wlvh2MYng97OHy5YFF1MJlMDpjNZnBfyAKiv4T2GObAwfv6DxR6Q7wxOrgjCyrUlpBKI5FIIhaPQ0gmobcN3uaQXhaQnSlkG9ex8XM9xWq5xK/djqtoNpsEFZqmod1uwzS/YTQaHQiOcUzMCdlbnyZTLBYL7J6f4XsuDyFH/n1RFBSoSpIMz/PgUIgMrGWGIJgThewhO7RcrbDf7zGdTqGSQr2lwzAMSlTjirvdLt93Oh1o9J9pmuj3H88VBuzz+Rzb7ZYscKCQQvGziJSQQjabIS8TqFZojEQRmUwW8YSAWCwGUSzTXfuUkKlbkbr1eg32Yx42Gvek6IE8VKGqKlqtFl9rVAeDwaHdQF1QDy37vs9b3Ww25KFHHiooFko0hzkoiowsDXo6nUI6k0HjvslFvA6FexhsggSDkWAK2CUWRLB2ac2DOfpC/sA6Vfh6OI9N/h/+NYMMvwFmKyCbKJqGLQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Checkboxes with both a white box and a normal checkbox. One is checked and the box next to it is teal.",
          "title": "Checkboxes with both a white box and a normal checkbox. One is checked and the box next to it is teal.",
          "src": "/static/0879c2ab147316c10116e9689c393503/08115/teal-checkbox.png",
          "srcSet": ["/static/0879c2ab147316c10116e9689c393503/12f09/teal-checkbox.png 148w", "/static/0879c2ab147316c10116e9689c393503/e4a3f/teal-checkbox.png 295w", "/static/0879c2ab147316c10116e9689c393503/08115/teal-checkbox.png 504w"],
          "sizes": "(max-width: 504px) 100vw, 504px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Add your custom checkmark`}</h2>
    <p>{`If you want to do a checkmark unicode to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::before`}</code>{` element’s content, you can very well do that. However, I want to get a little fancy. Now, we want to make sure that there is a perpendicular checkmark inside of our custom element. I’ve done this by adding an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::after`}</code>{` pseudo-element. What we are doing here is creating a right angle with two borders and rotating it.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox']:checked + label::after`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 3px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 27px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2px solid black`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2px solid black`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 6px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 13px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`-45deg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "540px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.13513513513513%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAB6UlEQVQoz3WTS2/aQBSF8yP7fxBVC1Q0UqKqS6SmMSaQ1uCwiMEBgwvUxC8MNqZhUSQe3XSVRZEiqtM7o9pyqbM4mvHM3ONvzsyczGYzeJ4Hy3ZgMzkOxuMxTNOEZVm87zguDIO1DtdkMgGrm06nXKwf6SSYz/F1OMDZ2zzenZ3jvFjE61cvkc1mUSqVkM/nUCyeIpPJoFAoIJfLQRDKCIIg3dCfTXHv+mjo33BjWniv9WAyCteNi6LFjM62bbg09yxh6HvQzBBvbn/itKPjRfUT7oYjXNeuUa1WIYgihMtLtNsqwjCMC4+NYkM2EQQ+vi8XeFitsH18pBxtMmhzqarKZRjGP1RJumT/r2GAh+USP7Zb/H564gdydVUhyhrq9ToqYgXuxOOEbG0YzhFS9seRxIa+7yPwA2w2G/za73l+mqah0+mi2+1CURT0+33ofR2DwQC9Xp9/H1PHhtHger3GngxN8x4NIhPLFZTLAkTKUZLqaMo3qFG2Hy4+ki7wWZI4zH+Gc8JfUX673Q6Hw4Hfv5bSgq5/IbpbIu1AaSmUZRvD0QiLxYJvn9Wlbpl1WB5LypGZmmQoN2TIRCTLRNZs8kwlIlLvOogeQ+qhJAfYIvYK2DYiimTLlEaVSpjU8dWIfvbcVUnqD/6lJOr9pN3nAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Teal checked box with a normal checked checkbox next to it.",
          "title": "Teal checked box with a normal checked checkbox next to it.",
          "src": "/static/a69ccbedad8dae7327fe8f7179e76aaa/07484/teal-checkbox-with-check.png",
          "srcSet": ["/static/a69ccbedad8dae7327fe8f7179e76aaa/12f09/teal-checkbox-with-check.png 148w", "/static/a69ccbedad8dae7327fe8f7179e76aaa/e4a3f/teal-checkbox-with-check.png 295w", "/static/a69ccbedad8dae7327fe8f7179e76aaa/07484/teal-checkbox-with-check.png 540w"],
          "sizes": "(max-width: 540px) 100vw, 540px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`An additional challenge, instead of a check, make an “X.”`}</p>
    <h2>{`Add focus styles to the pseudo-element`}</h2>
    <p>{`Great! Are we good to go now? Well, not quite.`}</p>
    <p>{`We still need to ensure that the pseudo-element “receives focus.” What we are going to do now is replicate the focus styling on when the checkbox receives focus. The reason why we don’t want to do `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`display: none`}</code>{` is because removing the display prevents the checkbox from receiving focus at all. I wanted to have some concrete focus styling since they can vary from browser to browser. Below is what I ended up doing because I wanted to replicate the default focus for Chrome, but in all browsers. It’s not the same, but it’s close!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox']:focus + label::before`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`outline`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #5d9dd5 solid 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0px 8px #5e9ed6`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "522px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.27027027027026%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAACJUlEQVQ4y4VTTW/aQBDN/7+0HGgOlF6aYoMBA4r5SkDly6GpEjA2NjYfUlRBi1y1CVIph0qvMxuWEIJapMfsjr2z7808nwwGA9i2DY6WZe0g94f5w3fkWY6ME5kYui6C8RiTyQRjiqORjyAYw/M8+L4v8hzH23eCwH9RTBTkBB+qViqIx99tEUfk9Sucnr6BqiqIRqMiH4lEKL5FLBajfBLWMYaC3XCIVrMJVVGgKCpSqZRALpdDtVpFPp9HJpNFOp1GIpEQyBcKxwvyH/djOp1iPp9jvV6Df7e3N6jV62i32yK2Wi30ej0hOQgCasnouORdD4klY7FY4P7+gQq1oBNDjVilNGb2AYZRhOM4/x8KP+RbZ7MZwjDEZrNB9+pKSC4VDZTLZUJFMLT3pr/vBFl4x5Bvtm0Hy+USq9UK3W4XBepTuVRChQaW03VqQ4+kekKuBE9+n+WuoOsOMSKE4Xf8Wv+G2enAODegJTXoOg9EI2RQyOXFcBSaMkOni/r9/hPDAWunRd/xcG358O++4W75ALN7jXq9hgpJLRaL1D8DFxeXaDQaqNVqog0G5fkZyz4oSE32pvjkfsHs6w/8XP9B6fIjNLIO20NVE8hmdZydvYeaTOKchrMv/ZlkuWBP9emmz5ZLTD3cUL/aZJUG+bND8k3TFMyavKe1HIrEi6E8eWkgGDu0dulzZEhL8Rcl949nnntwy/CxCMcdtpf8C7LAobH/AncesL+NnHW8AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Boxed item has a blue item around it, indicating the keyboard is focused on it.",
          "title": "Boxed item has a blue item around it, indicating the keyboard is focused on it.",
          "src": "/static/adc031ec052971fa7b4f7e1941c9bb47/29492/receiving-focus.png",
          "srcSet": ["/static/adc031ec052971fa7b4f7e1941c9bb47/12f09/receiving-focus.png 148w", "/static/adc031ec052971fa7b4f7e1941c9bb47/e4a3f/receiving-focus.png 295w", "/static/adc031ec052971fa7b4f7e1941c9bb47/29492/receiving-focus.png 522w"],
          "sizes": "(max-width: 522px) 100vw, 522px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now we can hide it the original checkbox! See how helpful keeping it around when we were figuring this out?`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox']`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1px 1px 1px 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* IE6, IE7 */`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Note: I have used the `}<a parentName="p" {...{
        "href": "https://a11yproject.com/posts/how-to-hide-content/"
      }}>{`visually-hidden`}</a>{` styling here. I would normally use a Sass mixin or a class for this.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/1ynEvIv4dpGRPB7cyI/giphy.gif",
        "alt": "Gif of keyboard focusing on custom checkboxes and checking"
      }}></img></p>
    <h2>{`Add some styling for the disabled checkboxes`}</h2>
    <p>{`One last thing, we should probably make that disabled checkbox stylistically different. Below is what I did:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox']:disabled + label`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #575757`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='checkbox']:disabled + label::before`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ddd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "516px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.432432432432435%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB4ElEQVQoz3VSS2/aYBDMP2577L9oVYk0JYFgWiAUzCtgG2MMhgYXRLAxYEx5iNzSQ3OJiKpGmu5+KREq5DDa7zk7uztH4/EY9bqBRDKJvCwjRzj5eIxU6gKynIUkSchms4jFJHqTwNd0GsVSCa47hOd5GA6f4hZHk8kElUoZoVAIp5FznJxG8eH9O5xFokhfpBAOhxGNRETkN8cEKf4ZjuMeJuQD3/cx/7HEW9PG67IJz5/BGQzg0p3rugLT6RSLxQLz+Ryz2ewg2T9CvnApo4dXeQ1vVAtK1RClxklJTIqTuk/I5Qvig+M4IsGWbE/hduE4A9zfb/Dw8Bu9bheGYcCyLDQaDTQbTUHEnw9hl/iZkLP+vL3F3d0vNJtNGsI5cjkZSRpW4ksCtv0dQTAFD5H7zuD1/ypFydtDfuRTr/r9PhHYaLVa6FxdiQSsdhstiqy83f4mlL+ocLVaYb1eo9frwjRN6NUqqgRdrwmiNiXgVqiqCkVRoGka+tfXGI1G+4QDmupms8Hj4x80SEGJvKaqGi7Ll/S5gkwmg0KxgJpRf6qEnMFxr+RdhcvlEjc3a3Q6NoqFojCwXtOhkE9T5Ekm1Kr685QPDmX3kPvB4GZPaQDsPVbCMQgCAd6/5EHGX3Fe3XOFkePoAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Disabled checkbox with dark grey box.",
          "title": "Disabled checkbox with dark grey box.",
          "src": "/static/6305c9b3e88c371c0615e1498f44a354/75dcb/disabled-checkbox.png",
          "srcSet": ["/static/6305c9b3e88c371c0615e1498f44a354/12f09/disabled-checkbox.png 148w", "/static/6305c9b3e88c371c0615e1498f44a354/e4a3f/disabled-checkbox.png 295w", "/static/6305c9b3e88c371c0615e1498f44a354/75dcb/disabled-checkbox.png 516w"],
          "sizes": "(max-width: 516px) 100vw, 516px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`So that’s it! You can apply the same principles to radio buttons as well.`}</p>
    <p>{`If you liked this blog post and are interested in learning more about accessibility, take my `}<a parentName="p" {...{
        "href": "https://a11y-with-lindsey.ck.page/b4e437f629"
      }}>{`10 days of a11y`}</a>{` free email course.`}</p>
    <p>{`Cheers! Have a great week!`}</p>
    <p><strong parentName="p">{`Disclaimer:`}</strong>{` I almost didn’t post this. Last night as I was scrolling through Twitter, I saw a post that has almost the same tips in it. After asking the Twitterverse if I should post it, I got an overwhelming amount of people saying “YES!” I haven’t read the other one, only skimmed, mostly because I didn’t want to impact my writing. However, I wanted to link it up for you, in case you wanted to read it: `}<a parentName="p" {...{
        "href": "https://webdesign.tutsplus.com/tutorials/how-to-make-custom-accessible-checkboxes-and-radio-buttons--cms-32074"
      }}>{`How to Make Custom Accessible Checkboxes and Radio Buttons`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      