import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
import CallToAction from '../../components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I’ve always loved exploring how to make semantic, accessible elements aesthetically pleasing. And yes, it is possible. Challenging myself to do this has improved my CSS skills.`}</p>
    <p>{`Today we are going to talk about `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`input[type="range"]`}</code>{`. These inputs where you select a value from a slider that has a thumb on it. You start with HTML that looks like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`wrapper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`range`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`min`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`max`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`100`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`volume`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`visually-hidden`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`volume`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Volume`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p><em parentName="p">{`NOTE: I use the `}<a parentName="em" {...{
          "href": "https://a11yproject.com/posts/how-to-hide-content/"
        }}>{`visually hidden`}</a>{` class to ensure that we have a label for screen readers.`}</em></p>
    <p>{`Selecting a purpose for an input range slider has many possibilities. You could use it for volume decibel. You could use it for an audio player. You could use it in a survey when rating from 1-10. Many times, when people make these features custom, they don’t think to use the semantic features. They make a bunch of divs, and don’t think about the keyboard or screen reader accessibility.`}</p>
    <h2>{`Considerations`}</h2>
    <p>{`Today I am going to be playing around with CSS to show you some of the possibilities. A lot of what I do in this post is a form of exploration. I learned a lot about `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`linear-gradient`}</code>{` from research for this post, and we’ll be going over how I perceived it.`}</p>
    <p>{`When I go through these steps, I am considering the following:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Browser support`}</strong>{` - I don’t talk about browser support often. But according to the `}<a parentName="li" {...{
          "href": ""
        }}>{`POUR`}</a>{` principles, your site and application must be `}<strong parentName="li">{`robust`}</strong>{`. In the post, we’ll be going over many pseudo-elements for robust browser support.`}</li>
      <li parentName="ol"><strong parentName="li">{`Providing a fallback`}</strong>{` - linear-gradient isn’t always supported. It looks neat, but we have to ensure we have a clear way of presenting the range even if it’s not possible`}</li>
      <li parentName="ol"><strong parentName="li">{`Don’t use JavaScript to hack`}</strong>{` - just because you can doesn’t mean you should.`}</li>
    </ol>
    <h2>{`Starting out`}</h2>
    <p>{`When we first create that HTML, the appearance is pretty unimpressive.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "326px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "23.64864864864865%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAY0lEQVQY05VPWwrAIAzz/qer9tdf/xTpATQjgjAnm6wQSh8JicOheu/48+PMDDlnlFI2cF9r/byTP0UJF2OEiEBVEULYkFIa3Xu/3cgjfxE8RaHDU+wl8lR+orW2kDi//d4dXhDlge15Vp8ZAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "An input range slider unstyled.",
          "title": "An input range slider unstyled.",
          "src": "/static/2536bf4a708645ec6004f9483f47a101/ce9b1/unstyled-input.png",
          "srcSet": ["/static/2536bf4a708645ec6004f9483f47a101/12f09/unstyled-input.png 148w", "/static/2536bf4a708645ec6004f9483f47a101/e4a3f/unstyled-input.png 295w", "/static/2536bf4a708645ec6004f9483f47a101/ce9b1/unstyled-input.png 326w"],
          "sizes": "(max-width: 326px) 100vw, 326px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The first thing we want to do is start with a clean slate in our CSS. We are going to add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position: relative;`}</code>{` on our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`wrapper`}</code>{` class. Then we add an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`appearance: none;`}</code>{` to our range input. If you check out the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/appearance"
      }}>{`Mozilla docs`}</a>{`, prepending `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-webkit-`}</code>{` should cover us for Firefox, Chrome, and edge.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.wrapper`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.756756756756754%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAS0lEQVQY062PCQoAIAgE+/9bPT7gEQVCkEHXgsiKjlrMzJsih8KrqotI2pOpZMCxRkQOAB28A10CwzOzI+K07Ai4Gvzy8hhPF96qAm0D3PUyiLe3AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The slider with no track, only the thumb.",
          "title": "The slider with no track, only the thumb.",
          "src": "/static/624cec81f6bfaa8f676a2f6f3cd4e5f3/e17e5/appearance-none.png",
          "srcSet": ["/static/624cec81f6bfaa8f676a2f6f3cd4e5f3/12f09/appearance-none.png 148w", "/static/624cec81f6bfaa8f676a2f6f3cd4e5f3/e4a3f/appearance-none.png 295w", "/static/624cec81f6bfaa8f676a2f6f3cd4e5f3/e17e5/appearance-none.png 400w"],
          "sizes": "(max-width: 400px) 100vw, 400px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`There are a few things that I am going to “normalize” before I start going into the pseudo-elements. I am adding a width and a transparent background. For this particular item, I am going to add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`max-width`}</code>{` as well.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`max-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 7rem`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 100%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` transparent`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Browser Specific Pseudo Elements`}</h2>
    <p>{`You’d think that you could just add a bunch of properties like height, background color, and margin, like this:`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-moz-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`max-width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 7rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`height`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0.9rem`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` #464646`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`However, if you use something like BrowserStack, you’ll see something like this in IE11.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "258px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.89189189189189%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB5UlEQVQ4y62TPW/TQBjH+xEQEyM75AUV0mI7sc/xyzl2QCwVaanFy4IYaBogCaYDYiIDNA1QBuincILsfIESmjGdmXlpRBYQb/5zdqs6UVki9aSf7uTT/fT4uf/NBEGA4xqhayZc9D/08c7toOv58DxvKnzfh+u6GAwGkTQSlu9WcC55HjmegOdy4PgsOC5E2Ocix77zEHjhCGJORDKRRKPRiIVr9YeQeR6XdQ2WKsPSCJsJTFVCQSEomkVQ3YAkSiASmUDJK8hcyGD9+XosvOM8Q4LeBl9yML9QPSSzUIN4tYoUqz4U1qp1rJYrqKzeO6CCB/ersK/Z2Hq7tS8M/vxCaWMbpx9/xOzr30i9+I7Uyx9Is/lM6ye0NyOcOHkKt27cRPAX+PzpC4Z7w4i9r0OMvo3Q2+6h97431sPaE8xKV0AuXUfWWj5EsGxIRRvzczzUvBZVslhawtJijL1so2CY2Hy1GQsfsV8hrPGWqsBQZNAx9HweJi3AUHXIIoFM5AnU//Wwzi4lK+RA2UFNo0dRNVAmpGxN9UnMghkloNlsxkLHcaIbDDcNakyFZVqsmCxaG61YuLJSRjqVjjbCzE1DeCZxNoHG07Ec7uz00Wl3otT73nR0/S7abhu7g92D2BzzW/4HfFiyE9PdHp4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "An input range that has a blue fill for the lower track on the left side of the thumb and white fill for the upper track on the right side of the thumb. There is a dark grey background behind the slider.",
          "title": "An input range that has a blue fill for the lower track on the left side of the thumb and white fill for the upper track on the right side of the thumb. There is a dark grey background behind the slider.",
          "src": "/static/5988055ab9757b0a840a1dff906257c0/d9489/IE-11-no-pseudo.png",
          "srcSet": ["/static/5988055ab9757b0a840a1dff906257c0/12f09/IE-11-no-pseudo.png 148w", "/static/5988055ab9757b0a840a1dff906257c0/d9489/IE-11-no-pseudo.png 258w"],
          "sizes": "(max-width: 258px) 100vw, 258px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Depending on what your analytics say for your audience’s browser usage, this may be moot. And take that knowledge and apply it how you please. But I believe in robust browser support, so I’m going to cover the following pseudo-elements:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`::-webkit-slider-runnable-track`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`::-moz-range-track`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`::-ms-track`}</code></li>
    </ul>
    <p>{`Note: if you want to use these pseudo-elements, you have to create the selectors separately. That’s because if a browser isn’t supported, it’ll ignore the entire block.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-webkit-slider-runnable-track`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.9rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #464646`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-moz-range-track`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.9rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #464646`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-ms-track`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.9rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` transparent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #464646`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-ms-fill-lower`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` transparent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In Chrome:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.43243243243243%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAq0lEQVQY062POwqEMABEczYxrR7BRLHKRT2DWERJISkFRQUddwKm0YUtdmDynXkhAh+d54l937Ft26unaQpe1/U1xzMyKMFhWRYYY6CUQlVVKMsyuigKNE2Dtm1R1zW01jHDmR12yYjAeZ6R5zmSJIGUMjpN0zAT2HUdsix7ZLhnl4wIPI4DzjkMw/CwtRbjOMJ7j77vXzPskhGA99//IbLEveAL38z7XzLUBRJ/nMBO1pL6AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Unstyled, round, light grey thumb, with a dark grey rectangular track.",
          "title": "Unstyled, round, light grey thumb, with a dark grey rectangular track.",
          "src": "/static/592e86633bd214adf53c10bd39cdd745/908b1/with-background-chrome.png",
          "srcSet": ["/static/592e86633bd214adf53c10bd39cdd745/12f09/with-background-chrome.png 148w", "/static/592e86633bd214adf53c10bd39cdd745/908b1/with-background-chrome.png 280w"],
          "sizes": "(max-width: 280px) 100vw, 280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`In Firefox:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "284px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.108108108108105%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAwElEQVQoz62RzQpFUBSFz6soQ/l7PSnCo5BiZG5GlJGRqYHHwHc7ioubeyd31erszl57ndXZYl1XlmV55I5vGukhIU/BD9yNn7Cbir7vcV2XMAwJguBC3/dp25a6rvE876MfRRGO4zAMw/G4KIoCVVWxLAvDMA7quo5pmnRdR1VVaJq23Z01tm2jKAplWW6G8zwjxnEkSRLyPCfLsgvTNKVpmi2hrO99ORPHMdM0vRPyJxx/KAsZ9Yn7Ur5pzlt+AeC74JGxVqEGAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Unstyled, round, light grey thumb, with a dark grey rectangular track.",
          "title": "Unstyled, round, light grey thumb, with a dark grey rectangular track.",
          "src": "/static/e54af5f2c924ec7327bebb997c24940e/62f93/firefox-new.png",
          "srcSet": ["/static/e54af5f2c924ec7327bebb997c24940e/12f09/firefox-new.png 148w", "/static/e54af5f2c924ec7327bebb997c24940e/62f93/firefox-new.png 284w"],
          "sizes": "(max-width: 284px) 100vw, 284px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`In Edge:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "278px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "41.21621621621622%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAxklEQVQoz7VRMQ6CQBDkI4ZSPiAIjaK0UkkpUEjAJ3okPIeGmoMCGNlFCFeLk8xlMrsz2eQ0jBiGAV3X/UTqIGiz2ALUpZGQUqIoCoi3QC5yCIWTl3/1esbemKEsdfCF9JRlCe/iwTyYcGwH9tFmWqYF9+xC3+kw9gbck8vePKddynhXjzuWwqqqkKUvhI8QcRQvjMIIyTOBf/MR3APW5K13KJOlGXcshX3f88l1XSuU9eS1bYumaRRP2Ruz1PGvTxlYbEHCB4rnKY8SiTMYAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Unstyled, square, black thumb, with a dark grey rectangular track.",
          "title": "Unstyled, square, black thumb, with a dark grey rectangular track.",
          "src": "/static/6a8dc8411d72c08a84c08d3f48cd96fe/4e29d/edge.png",
          "srcSet": ["/static/6a8dc8411d72c08a84c08d3f48cd96fe/12f09/edge.png 148w", "/static/6a8dc8411d72c08a84c08d3f48cd96fe/4e29d/edge.png 278w"],
          "sizes": "(max-width: 278px) 100vw, 278px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`In IE11:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "266px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.05405405405405%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA7ElEQVQoz7VRQQ7BQBR1D7Ykaoeqhig7BguchBNwFpa6IzHjDGxpa0HCuhdo+/SPVBE79ZI3eTP/v5f5+SmECIIgERJSdPi+D8/zfiJlyMAoOQlQlvzh5XzBerWG4AKc83duOIQQ2Iqt1J918pB3v9vHIy/mCyh5BXpVh6Zq0CoPqmUVRsNALptDJp2Rmt6iOpE8BaWAyXgSB5pLE02jiX6vjy7rPsnaDMPBEPVaHaViCaPBCKzD3nrI0zJamE1ncaDrujgcjrBtG7b1wvBuWRYcx8HJOUn9recYem/X2x+XQoLW/iufIyeJxAPvc6UIJtP533sAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Unstyled, square, black thumb, with a dark grey rectangular track.",
          "title": "Unstyled, square, black thumb, with a dark grey rectangular track.",
          "src": "/static/1668b9c37f1f81f59776093f44244e0d/b4ffe/IE-11-new.png",
          "srcSet": ["/static/1668b9c37f1f81f59776093f44244e0d/12f09/IE-11-new.png 148w", "/static/1668b9c37f1f81f59776093f44244e0d/b4ffe/IE-11-new.png 266w"],
          "sizes": "(max-width: 266px) 100vw, 266px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The main difference between the properties inside the blocks is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::-ms-track`}</code>{` pseudo-element needs a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`color: transparent`}</code>{`. We also need to have a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::-ms-fill-lower`}</code>{` otherwise it looks like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "45.27027027027027%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABFElEQVQoz7XRsUrDUBQG4LyAoK+gdRIsik0nQZ/BYJxSqAni7mZnJ6mDuAla38K5SS44mKEdnazSitRizb03N+bm97YaJYgu1QMf55zl58DRoCpN04llOVq2TFpZjva+SPDwGWz4pAxynYYviESsOgWjDJRShX3OnPP8haPhoT+EdXqDzcYjzEYP5rly1oV50Yd1cg1jq4pdZwd21YG9bY85toOKVUFtv4Y4jvOBt70BFg46mD16RaHOMFfnKByGmD8GlvYuMT01g/XVNawsl6CX9LGyXkZxsQhjw4AQIh8YRQKk3UGzdQ+3fYem4rY+qNm/CkA8H8QnXwiB53oIggBSyn9/SopE/iJJfjS67ttT/rLeAFD/jlC6wi/QAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "In IE11, unstyled, square, black thumb, with a rectangular track. The lower left track is a light blue and the upper right track is dark grey.",
          "title": "In IE11, unstyled, square, black thumb, with a rectangular track. The lower left track is a light blue and the upper right track is dark grey.",
          "src": "/static/46469b39cbd356282b3eec7726c0141d/5a46d/no-fill-lower-ms.png",
          "srcSet": ["/static/46469b39cbd356282b3eec7726c0141d/12f09/no-fill-lower-ms.png 148w", "/static/46469b39cbd356282b3eec7726c0141d/e4a3f/no-fill-lower-ms.png 295w", "/static/46469b39cbd356282b3eec7726c0141d/5a46d/no-fill-lower-ms.png 300w"],
          "sizes": "(max-width: 300px) 100vw, 300px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`When we add all these in, we have a starting out point for our volume slider:`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Volume Slider - Starting out" src="https://codepen.io/littlekope0903/embed/KKpMvXM?height=450&theme-id=default&default-tab=css,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/littlekope0903/pen/KKpMvXM">
    Volume Slider - Starting out
  </a>{' '}
  by Lindsey Kopacz (
  <a href="https://codepen.io/littlekope0903">@littlekope0903</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Now we get to play around with the part I explored the most - linear gradient.`}</p>
    <h2>{`Using linear-gradient`}</h2>
    <p>{`Linear gradient confused me for the longest time. Because I focus on accessibility more than CSS, I never learned all the fun CSS functions. I want a volume bar that shows a triangle going from the lowest step to the highest step while giving a screen reader user the ability to control volume decibel.`}</p>
    <p>{`Our goal would be to have something that looks similar to this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "270px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.054054054054056%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA0klEQVQY042QMQqDQBBFPVVWsEsuYR9rW2u9g3gJ8Q42XkAXRAtRQQsLLQRB1B9mzZpgkfjhMezy58/OKnhr27ajSuR5WZajnpE+6VVk87qugrN5miY0TYNfGsdR9JIU/BEFt22LqqqQZRmiKEIQBPA8D7ZtwzRNGE8DwzDsgfQKmkANcRwjDEP4vg/XdWFZFhzHQV3X0HUdmqZBVVUBuzEwtvO4P9D3/SeQ1uq6DkVRgCccSZKAcy5I01QE5nmOsizF4G/kHW1yaWXSPM9XbOLPX1c8s/lif2FUAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A dark grey right triangle, the right angle being the bottom right. There is a tall yet narrow rounded corner thumb.",
          "title": "A dark grey right triangle, the right angle being the bottom right. There is a tall yet narrow rounded corner thumb.",
          "src": "/static/43589d86710995ed981d871281fd8fec/01bf6/volume-goal.png",
          "srcSet": ["/static/43589d86710995ed981d871281fd8fec/12f09/volume-goal.png 148w", "/static/43589d86710995ed981d871281fd8fec/01bf6/volume-goal.png 270w"],
          "sizes": "(max-width: 270px) 100vw, 270px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`linear-gradient`}</code>{` is a CSS function that transforms a background from one or many colors into another. This function takes on many arguments and can be very simple or very complex.`}</p>
    <p>{`From the most basic standpoint, here is what to consider when you’re creating a linear gradient:`}</p>
    <ul>
      <li parentName="ul">{`The angles and the direction of the gradient`}</li>
      <li parentName="ul">{`What colors you want in the gradient.`}</li>
      <li parentName="ul">{`What percent points you want the “fade” to start and stop. More formally, we call these color stops.`}</li>
    </ul>
    <p>{`I have a few examples that I played with that I show in the following CodePens.`}</p>
    <p>{`First a rainbow:`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Linear Gradient - Blurry Rainbow" src="https://codepen.io/littlekope0903/embed/povBzqX?height=450&theme-id=default&default-tab=css,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/littlekope0903/pen/povBzqX">
    Linear Gradient - Blurry Rainbow
  </a>{' '}
  by Lindsey Kopacz (
  <a href="https://codepen.io/littlekope0903">@littlekope0903</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`A few of my observations:`}</p>
    <ul>
      <li parentName="ul">{`If there’s no direction or angle specified, it assumes a horizontal (0deg) angle and top to bottom direction.`}</li>
      <li parentName="ul">{`If you don’t specify where the colors stop, the gradient assumes even distribution.`}</li>
    </ul>
    <p>{`Now a French Flag:`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Linear Gradient - French Flag" src="https://codepen.io/littlekope0903/embed/LYEvPay?height=450&theme-id=default&default-tab=css,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/littlekope0903/pen/LYEvPay">
    Linear Gradient - French Flag
  </a>{' '}
  by Lindsey Kopacz (
  <a href="https://codepen.io/littlekope0903">@littlekope0903</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`My first observation: I associate linear gradients as fades. But if you want a straight line, you have to put the “stop” to be at equal values.`}</p>
    {
      /* prettier-ignore */
    }
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.flag--fr`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`linear-gradient`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* The gradient is going toward the right */`}</span>{`
    to right`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* It starts at blue and stops in full opacity at 33% */`}</span>{`
    blue 33%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* White starts in full opacity at 33% */`}</span>{`
    white 33%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* White ends in full opacity at 66% */`}</span>{`
    white 66%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Red starts in full opacity at 66%
    and will continue till the background is over */`}</span>{`
    red 66%
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`What if we want to start playing with fades and make it more blurry?`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Linear Gradient - blurry French Flag" src="https://codepen.io/littlekope0903/embed/yLyrBQm?height=450&theme-id=default&default-tab=css,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/littlekope0903/pen/yLyrBQm">
    Linear Gradient - blurry French Flag
  </a>{' '}
  by Lindsey Kopacz (
  <a href="https://codepen.io/littlekope0903">@littlekope0903</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`There is a gap between the stops at full opacity, and in between that gap, there is a fade. Fun stuff!`}</p>
    {
      /* prettier-ignore */
    }
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.flag--fr`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`linear-gradient`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    to right`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Blue stops at 27%, starts to
    fade into the next color */`}</span>{`
    blue 27%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* White starts at 33% */`}</span>{`
    white 33%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* White stops at 66%, starts to
    fade into next color */`}</span>{`
    white 66%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Red starts at 73% */`}</span>{`
    red 73%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "590px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "58.78378378378378%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACPUlEQVQoz42TS2sTURTHJw9a0M+QRVfaRTFFELRC7MZ+B8GFgoKgRcFl6SYLaVct9Ct01dSa0LyrCXmRpCFJ20kEzbKPNGkhk9C5ec39e+6dPnwU9cCZO5wz93fP/Z8zimFwXG86QqEQ4vE4CoUC0uk0otEo9g8PkVFVfKJ4JJtFvFyGn3LFnR25SxGPWq2HpSWGlZUuOcPycg/+kEawL9ja+oxcLodwOIxNvx8nBwfY+7AAdX4eXxcX8d3txt7cHL4Vi1fA1dUzKEqffEg+kP7kuYHBgKHdbkPTNHQZgzcQQGEzAEzPgDkc6CqKdCZWqvoSuL7OCMIxMgKMjlKQoq/e/inCWb+P/WyekrOA02l+KDYpFug/A9fWdMoZsFgAq5VL+MtZoS2H0JhzWodDk6p1wO9NgROM22wSapD/JxASJpzIkEc0ToCpRybQav030GYzgS/eiIxxXiGBCCiteQr+wPV3oMejy4bY7JygHDducrx+fz5OsjgugbJCAXw4bQLtdnGl6yrsSX2lxtSUxzOk+zugWlWp08NfOyOufPc+LjcIsADu7l4AOTY2juFwNDA21oDL1cCt26dwL3B4fR+Rz+XRarVQPzpCq9NBr9GE/vQZ2Pg42OQk2B0n9IkJsFrNBNboJRaLIZGIYXs7i1QqQYOcRbN5jCINa5n+hGq1CpWuVCqVoNFcChtKha+cmx2EUqlU4PV6EQwGEYlESE+PfK/X6/D5fEgmk3RICplMhg5NoENVXnT/dxOxH16G9h/53KPtAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "french gradient annotated",
        "title": "french gradient annotated",
        "src": "/static/2cc140ca956fac82f7e4efe9776b2c5f/fcda8/french-gradient-annotated.png",
        "srcSet": ["/static/2cc140ca956fac82f7e4efe9776b2c5f/12f09/french-gradient-annotated.png 148w", "/static/2cc140ca956fac82f7e4efe9776b2c5f/e4a3f/french-gradient-annotated.png 295w", "/static/2cc140ca956fac82f7e4efe9776b2c5f/fcda8/french-gradient-annotated.png 590w", "/static/2cc140ca956fac82f7e4efe9776b2c5f/efc66/french-gradient-annotated.png 885w", "/static/2cc140ca956fac82f7e4efe9776b2c5f/c83ae/french-gradient-annotated.png 1180w", "/static/2cc140ca956fac82f7e4efe9776b2c5f/669eb/french-gradient-annotated.png 1244w"],
        "sizes": "(max-width: 590px) 100vw, 590px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`I’m still a complete newb to this stuff, so I bet I am missing many details. But this is my “explain like I’m five” version of this.`}</p>
    <p>{`So, this feels like a tangent, but I promise it’s not. We are going to use what we learned to create a volume range. We want to visually indicate that the volume is getting higher when we increase the range value. So we are going to take the code we were using at the end of the Starting Out section, and add a linear-gradient to it. Remember, these lines won’t be blurry. We’ll need to make sure that the percentage of the gradient matches.`}</p>
    {
      /* prettier-ignore */
    }
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type=range]::-webkit-slider-runnable-track`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.9rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #464646`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`linear-gradient`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    to bottom right`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    transparent 50%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    #464646 50%`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type=range]::-moz-range-track`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.9rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #464646`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`linear-gradient`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    to bottom right`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    transparent 50%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    #464646 50%`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type=range]::-ms-track`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.9rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` transparent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #464646`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`linear-gradient`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    to bottom right`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    transparent 50%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    #464646 50%`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now, this looks like a right triangle, with the 90degrees mark being at the bottom right-hand corner.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "304px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.72972972972973%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAyUlEQVQY042RwQmDQBRE7cdCrMAWLMEiRNEEPHi1ASvwLnryJojeFEQ8igjqS1xYWUMgGRh29zMMf3Y03jiOQ1C97/suKLFtm6DUqVBn2jeBinVdmef5NluWhWmaqOuavu9vi2jq4+RpMI4jbduS5zlN09B1Hc/HE9u2sSwL0zQxDANd14mi6EpwGZ7RhmGgLEvSNCVJEuI4xvd9siyjqipc18VxHDzPIwgCwjAUZ1EUSA9h+CvyP7j94WdktRAZ46Qs5ZOyOKl7AdDrvyRxyIMWAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A dark grey right triangle, the right angle being the bottom right. The thumb is the original round thumb.",
          "title": "A dark grey right triangle, the right angle being the bottom right. The thumb is the original round thumb.",
          "src": "/static/06cedd3fd6d5c19ad45e8d1ea6dfd560/c1724/gradient-without-thumb.png",
          "srcSet": ["/static/06cedd3fd6d5c19ad45e8d1ea6dfd560/12f09/gradient-without-thumb.png 148w", "/static/06cedd3fd6d5c19ad45e8d1ea6dfd560/e4a3f/gradient-without-thumb.png 295w", "/static/06cedd3fd6d5c19ad45e8d1ea6dfd560/c1724/gradient-without-thumb.png 304w"],
          "sizes": "(max-width: 304px) 100vw, 304px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`My SCSS linter will yell at me about having 2 background properties. But I like having the background as a solid color as a fallback. If the browser doesn’t support linear-gradient, we have a browser fallback.`}</p>
    <h2>{`Designing the thumb`}</h2>
    <p>{`Now, with my goal, I want the thumb to be narrow and taller. CSS wise, this is relatively simple. Here’s the list of the pseudo-elements to make sure the thumb works across browsers:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`::-webkit-slider-thumb`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`::-moz-range-thumb`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`::-ms-thumb`}</code></li>
    </ul>
    <p>{`Again, we have to separate these all out into their separate selectors, the same as the track.`}</p>
    <p>{`We want to set the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-webkit-appearance: none;`}</code>{` and set height, width, border, and background. For Chrome, we add a negative `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`margin-top`}</code>{` to balance out the positioning. The rest have no `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`margin-top`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-webkit-slider-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -5px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-moz-range-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-ms-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.75rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now we will see the track is tall and white with a black border.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "274px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "36.486486486486484%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAAA3ElEQVQoz4VROwqEMBDNgaxFQfAcXsI72FmsXkGsvIF4BhsbQdDCH4gIYmXj7y0JJCib3X3wmEmYeXmZIZDguq5Hfp6niJTHcQjyWhopyf0gK17XFcMw4BdoHwe5u5GBCo/jiHme0fc9sixDkiQIwxC+78O2bURRJISZw33fMU0TqqpCmqaI4xhBEMBxHLiui6ZpYFkWTNOEYRjQdR2qqkLTNCiKAu/liccJT5ZlYQ6KokCe5w9SwbIsUdc1y9u2Zey6jt1R93x0f79MsW3b3xqu87EUGfl8vvG+6Td0LQchqjFHqQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A dark grey right triangle, the right angle being the bottom right. There is a tall yet narrow rectangular thumb.",
          "title": "A dark grey right triangle, the right angle being the bottom right. There is a tall yet narrow rectangular thumb.",
          "src": "/static/b0a9ff2e12c1b736dfa360c962cd9675/d3fa7/rectangle-thumb.png",
          "srcSet": ["/static/b0a9ff2e12c1b736dfa360c962cd9675/12f09/rectangle-thumb.png 148w", "/static/b0a9ff2e12c1b736dfa360c962cd9675/d3fa7/rectangle-thumb.png 274w"],
          "sizes": "(max-width: 274px) 100vw, 274px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now we can just round out the edges and add a pointer cursor.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-webkit-slider-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -5px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`border-radius`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 3px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`cursor`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` pointer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-moz-range-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`border-radius`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 3px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`cursor`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` pointer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']::-ms-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-appearance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.75rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.5rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`border-radius`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 3px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`cursor`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` pointer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The source that helped me out a lot with this section: `}<a parentName="p" {...{
        "href": "https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/#article-header-id-1"
      }}>{`CSS Tricks`}</a></p>
    <h2>{`Adding custom focus states`}</h2>
    <p>{`I want to remove the focus styles (gasp!) from the entire input, and put it on the thumb itself. I couldn’t figure out how to get rid of it on Firefox, so if you know, tweet at me and let me know!`}</p>
    <p>{`I am going to remove the outline on the entire input, and instead all box shadows to the input range on focus:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']:focus`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`outline`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']:focus::-webkit-slider-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0px 0px 7px 3px #0065c4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']:focus::-moz-range-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0px 0px 7px 3px #0065c4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='range']:focus::-ms-thumb`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0px 0px 7px 3px #0065c4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Volume Slider" src="https://codepen.io/littlekope0903/embed/PowVaJd?height=450&theme-id=default&default-tab=css,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/littlekope0903/pen/PowVaJd">Volume Slider</a> by
  Lindsey Kopacz (
  <a href="https://codepen.io/littlekope0903">@littlekope0903</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <h2>{`When it gets hairy`}</h2>
    <p>{`If you want the area between the thumb and the start of the range to be a different color, it can begin to get complicated. This differentiation indicates progress, not to be confused with the progress element.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "14.864864864864865%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAlUlEQVQI142MsQ7BUBSG+05iFSujeAE2Jk/gDbyE2W5RKiFdmnawSLARcS0SDemVns+5xdbByfnyn5M/+bzj2eCvIpZhQhDGpbhuvo6IN1seVjB3MKlmSsFF/9tTAMEbT6ZUGh3q7T61Vq8U11WbXQbDEVcV+HtYHKQgUGY7SE5foYiQ2Rf2DzJryXNBV/nl5xbn03kDSx3M/P4DTvUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "An audio bar slider with a round white thumb. The left of the thumb is navy blue indicating progess. The right of the thumb is pale blue, indicating non played audio.",
          "title": "An audio bar slider with a round white thumb. The left of the thumb is navy blue indicating progess. The right of the thumb is pale blue, indicating non played audio.",
          "src": "/static/4c9ea1c6ec19b789c5c46d338b179e40/fcda8/audio-progress.png",
          "srcSet": ["/static/4c9ea1c6ec19b789c5c46d338b179e40/12f09/audio-progress.png 148w", "/static/4c9ea1c6ec19b789c5c46d338b179e40/e4a3f/audio-progress.png 295w", "/static/4c9ea1c6ec19b789c5c46d338b179e40/fcda8/audio-progress.png 590w", "/static/4c9ea1c6ec19b789c5c46d338b179e40/1b1d5/audio-progress.png 876w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Unfortunately, using pseudo-elements, we only have support for Edge, IE, and Firefox. There is the pseudo-element `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::-ms-fill-lower`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::-moz-range-progress`}</code>{`, but none for Chrome. I’ve played with doing this in JavaScript, but it sounds like a performance nightmare and too much math.`}</p>
    <p>{`You can check out the CodePen where I use these pseudo-elements.`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Slider with Progress Bar (Supports Mozilla, Edge, &amp; IE)" src="https://codepen.io/littlekope0903/embed/rNabEbm?height=450&theme-id=default&default-tab=css,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/littlekope0903/pen/rNabEbm">
    Slider with Progress Bar (Supports Mozilla, Edge, &amp; IE)
  </a>{' '}
  by Lindsey Kopacz (
  <a href="https://codepen.io/littlekope0903">@littlekope0903</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <h2>{`Conclusion`}</h2>
    <p>{`Thanks for reading and learning with me! I hope I’ve helped you explore and also realize that aesthetic and accessibility are not mutually exclusive. You just have to take some time and think it through.`}</p>
    <p>{`If you liked this blog post and are interested in learning more about accessibility, take my `}<a parentName="p" {...{
        "href": "https://a11y-with-lindsey.ck.page/b4e437f629"
      }}>{`10 days of a11y`}</a>{` free email course.`}</p>
    <p>{`Cheers! Have a great week!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      