// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-2018-year-in-review-index-md": () => import("./../../../src/pages/2018-year-in-review/index.md" /* webpackChunkName: "component---src-pages-2018-year-in-review-index-md" */),
  "component---src-pages-4-things-ive-learned-about-dyslexia-index-md": () => import("./../../../src/pages/4-things-ive-learned-about-dyslexia/index.md" /* webpackChunkName: "component---src-pages-4-things-ive-learned-about-dyslexia-index-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-11-y-taught-js-p-1-index-md": () => import("./../../../src/pages/a11y-taught-js-p1/index.md" /* webpackChunkName: "component---src-pages-a-11-y-taught-js-p-1-index-md" */),
  "component---src-pages-a-11-y-taught-js-p-2-index-md": () => import("./../../../src/pages/a11y-taught-js-p2/index.md" /* webpackChunkName: "component---src-pages-a-11-y-taught-js-p-2-index-md" */),
  "component---src-pages-a-11-y-testing-workflow-index-md": () => import("./../../../src/pages/a11y-testing-workflow/index.md" /* webpackChunkName: "component---src-pages-a-11-y-testing-workflow-index-md" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessible-basic-nav-index-mdx": () => import("./../../../src/pages/accessible-basic-nav/index.mdx" /* webpackChunkName: "component---src-pages-accessible-basic-nav-index-mdx" */),
  "component---src-pages-accessible-styled-checkboxes-index-mdx": () => import("./../../../src/pages/accessible-styled-checkboxes/index.mdx" /* webpackChunkName: "component---src-pages-accessible-styled-checkboxes-index-mdx" */),
  "component---src-pages-accessible-styled-radio-index-mdx": () => import("./../../../src/pages/accessible-styled-radio/index.mdx" /* webpackChunkName: "component---src-pages-accessible-styled-radio-index-mdx" */),
  "component---src-pages-aria-labels-index-md": () => import("./../../../src/pages/aria-labels/index.md" /* webpackChunkName: "component---src-pages-aria-labels-index-md" */),
  "component---src-pages-aria-states-index-mdx": () => import("./../../../src/pages/aria-states/index.mdx" /* webpackChunkName: "component---src-pages-aria-states-index-mdx" */),
  "component---src-pages-blog-birthday-1-index-md": () => import("./../../../src/pages/blog-birthday-1/index.md" /* webpackChunkName: "component---src-pages-blog-birthday-1-index-md" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cli-tools-index-md": () => import("./../../../src/pages/cli-tools/index.md" /* webpackChunkName: "component---src-pages-cli-tools-index-md" */),
  "component---src-pages-color-and-accessibility-index-md": () => import("./../../../src/pages/color-and-accessibility/index.md" /* webpackChunkName: "component---src-pages-color-and-accessibility-index-md" */),
  "component---src-pages-data-viz-a-11-y-bar-chart-index-mdx": () => import("./../../../src/pages/data-viz-a11y-bar-chart/index.mdx" /* webpackChunkName: "component---src-pages-data-viz-a-11-y-bar-chart-index-mdx" */),
  "component---src-pages-donut-chart-index-md": () => import("./../../../src/pages/donut-chart/index.md" /* webpackChunkName: "component---src-pages-donut-chart-index-md" */),
  "component---src-pages-first-month-blogging-index-md": () => import("./../../../src/pages/first-month-blogging/index.md" /* webpackChunkName: "component---src-pages-first-month-blogging-index-md" */),
  "component---src-pages-focus-within-index-md": () => import("./../../../src/pages/focus-within/index.md" /* webpackChunkName: "component---src-pages-focus-within-index-md" */),
  "component---src-pages-html-5-aria-index-md": () => import("./../../../src/pages/html5-aria/index.md" /* webpackChunkName: "component---src-pages-html-5-aria-index-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-input-range-index-mdx": () => import("./../../../src/pages/input-range/index.mdx" /* webpackChunkName: "component---src-pages-input-range-index-mdx" */),
  "component---src-pages-js-a-11-y-index-md": () => import("./../../../src/pages/js-a11y/index.md" /* webpackChunkName: "component---src-pages-js-a-11-y-index-md" */),
  "component---src-pages-js-accordion-index-md": () => import("./../../../src/pages/js-accordion/index.md" /* webpackChunkName: "component---src-pages-js-accordion-index-md" */),
  "component---src-pages-js-tips-index-mdx": () => import("./../../../src/pages/js-tips/index.mdx" /* webpackChunkName: "component---src-pages-js-tips-index-mdx" */),
  "component---src-pages-keyboard-accessibility-101-index-md": () => import("./../../../src/pages/keyboard-accessibility-101/index.md" /* webpackChunkName: "component---src-pages-keyboard-accessibility-101-index-md" */),
  "component---src-pages-manual-testing-checklist-index-md": () => import("./../../../src/pages/manual-testing-checklist/index.md" /* webpackChunkName: "component---src-pages-manual-testing-checklist-index-md" */),
  "component---src-pages-my-blogging-workflow-index-md": () => import("./../../../src/pages/my-blogging-workflow/index.md" /* webpackChunkName: "component---src-pages-my-blogging-workflow-index-md" */),
  "component---src-pages-my-rant-this-isnt-edge-case-index-md": () => import("./../../../src/pages/my-rant-this-isnt-edge-case/index.md" /* webpackChunkName: "component---src-pages-my-rant-this-isnt-edge-case-index-md" */),
  "component---src-pages-negative-impact-inaccessible-forms-index-md": () => import("./../../../src/pages/negative-impact-inaccessible-forms/index.md" /* webpackChunkName: "component---src-pages-negative-impact-inaccessible-forms-index-md" */),
  "component---src-pages-podcast-a-11-y-index-md": () => import("./../../../src/pages/podcast-a11y/index.md" /* webpackChunkName: "component---src-pages-podcast-a-11-y-index-md" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-react-packages-a-11-y-index-mdx": () => import("./../../../src/pages/react-packages-a11y/index.mdx" /* webpackChunkName: "component---src-pages-react-packages-a-11-y-index-mdx" */),
  "component---src-pages-reduce-motion-index-md": () => import("./../../../src/pages/reduce-motion/index.md" /* webpackChunkName: "component---src-pages-reduce-motion-index-md" */),
  "component---src-pages-speaker-accessibility-index-md": () => import("./../../../src/pages/speaker-accessibility/index.md" /* webpackChunkName: "component---src-pages-speaker-accessibility-index-md" */),
  "component---src-pages-thank-you-order-js": () => import("./../../../src/pages/thank-you-order.js" /* webpackChunkName: "component---src-pages-thank-you-order-js" */),
  "component---src-pages-thanks-digest-js": () => import("./../../../src/pages/thanks-digest.js" /* webpackChunkName: "component---src-pages-thanks-digest-js" */),
  "component---src-pages-thanks-egghead-js": () => import("./../../../src/pages/thanks-egghead.js" /* webpackChunkName: "component---src-pages-thanks-egghead-js" */),
  "component---src-pages-thanks-email-course-js": () => import("./../../../src/pages/thanks-email-course.js" /* webpackChunkName: "component---src-pages-thanks-email-course-js" */),
  "component---src-pages-thanksgiving-2018-index-md": () => import("./../../../src/pages/thanksgiving-2018/index.md" /* webpackChunkName: "component---src-pages-thanksgiving-2018-index-md" */),
  "component---src-pages-transitioning-from-remote-index-md": () => import("./../../../src/pages/transitioning-from-remote/index.md" /* webpackChunkName: "component---src-pages-transitioning-from-remote-index-md" */),
  "component---src-pages-twitter-a-11-y-index-md": () => import("./../../../src/pages/twitter-a11y/index.md" /* webpackChunkName: "component---src-pages-twitter-a-11-y-index-md" */),
  "component---src-pages-writing-alternative-text-that-matters-index-md": () => import("./../../../src/pages/writing-alternative-text-that-matters/index.md" /* webpackChunkName: "component---src-pages-writing-alternative-text-that-matters-index-md" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

