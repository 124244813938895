import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Content Warning: This blog post contains gifs.`}</em></p>
    <p>{`Hey friends! In this post, I am going to walk you through a newer media query (to me): `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`prefers-reduced-motion`}</code>{`.`}</p>
    <p>{`Quick confession: I know CSS fundamentals, but I am pretty behind on all the new stuff that has been coming out. Because my focus is usually on accessibility, I am focusing far more on HTML and JavaScript. When I focus on CSS, it’s ensuring proper color contrast or custom focus states. Sometimes I use CSS to make `}<a parentName="p" {...{
        "href": "/blog/create-custom-keyboard-accesible-checkboxes"
      }}>{`checkboxes keyboard accessible`}</a>{`. I always associated media queries with responsive design. I never thought of media queries as an accessibility enhancement.`}</p>
    <p>{`In this post, we are updating my blog by:`}</p>
    <ol>
      <li parentName="ol">{`Adding the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`prefers-reduced-motion`}</code>{` query`}</li>
      <li parentName="ol">{`Adding a user-controlled setting for reducing motion.`}</li>
    </ol>
    <h2>{`Understanding `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`prefers-reduced-motion`}</code></h2>
    <p>{`Animations, zooming, and panning can be problematic for people who have vestibular disorders. These disorders can cause motion sickness and vertigo. These are uncomfortable feelings that you don’t want to deal with ever, let alone on a website. From what I understand, the `}<a parentName="p" {...{
        "href": "https://a11yproject.com/posts/understanding-vestibular-disorders/"
      }}>{`vestibular system`}</a>{` is in your inner ear and helps to control balance.`}</p>
    <p>{`According to `}<a parentName="p" {...{
        "href": "https://vestibular.org/understanding-vestibular-disorder"
      }}>{`vestibular.org`}</a>{`, up to 35% of adults aged 40 years or older in the United States have experienced some form of vestibular dysfunction. So this is not a small problem.`}</p>
    <p>{`My major takeaways from the web accessibility perspective:`}</p>
    <ol>
      <li parentName="ol">{`Be careful with your animations.`}</li>
      <li parentName="ol">{`Be careful with your gifs.`}</li>
      <li parentName="ol">{`Use `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`prefers-reduced-motion`}</code>{`.`}</li>
      <li parentName="ol">{`Allow a user to control reduce motion.`}</li>
    </ol>
    <h3>{`How you do it`}</h3>
    <p>{`Implementing the query is pretty simple:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`prefers-reduced-motion`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` reduce`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Reduced Motion Code */`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`There are a few places I have some animations for my links.`}</p>
    <p>{`First, there are my links where I have a border-bottom that moves down when you hover over it.`}</p>
    <img {...{
      "className": "center",
      "src": "https://media.giphy.com/media/MY7TMjVSbUUITM2Brh/giphy.gif",
      "alt": ""
    }}></img>
    <p>{`Then there is my call to action link that scales 1.1x larger when we hover over it.`}</p>
    <img {...{
      "className": "center",
      "src": "https://media.giphy.com/media/XdIJqlqTIXZG3SyS3V/giphy.gif",
      "alt": ""
    }}></img>
    <p>{`I was talking to `}<a parentName="p" {...{
        "href": "https://twitter.com/andybelldesign/"
      }}>{`Andy Bell`}</a>{`, and he gave me `}<a parentName="p" {...{
        "href": "https://github.com/andybelldesign/stalfos/blob/feature/v2/src/utilities/_reset.scss#L71"
      }}>{`some suggestions`}</a>{` on implementation.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`prefers-reduced-motion`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` reduce`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`animation-play-state`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` paused `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`scroll-behavior`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` auto `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`After implementing the changes, we have the hover effects, but none of the transitions.`}</p>
    <img {...{
      "className": "center",
      "src": "https://media.giphy.com/media/W30RUUrYrc94jOvaau/giphy.gif",
      "alt": ""
    }}></img>
    <img {...{
      "className": "center",
      "src": "https://media.giphy.com/media/fszlV41WjnJrjIpoVx/giphy.gif",
      "alt": ""
    }}></img>
    <p>{`This strategy technically works well. However, I want to remove the hover effect altogether and keep the link as an underline. I may also play with the scale.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`prefers-reduced-motion`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` reduce`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`animation-play-state`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` paused `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`scroll-behavior`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` auto `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`a`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`padding-bottom`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`border-bottom`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` none`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`text-decoration`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` underline`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`With that change, now all my links are just a simple underline.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "376px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "86.48648648648648%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAABYlAAAWJQFJUiTwAAACRElEQVQ4y61T7U/SURS+f2b2JddWtjb9RDVTa0VJqauVklYiiEoMfgQCguCGJma+BQkW8mJ9KDXX2lpm8yV7Ouf+ftcX4EsbHx4u55x77nnu8/yuGFsFagnBP+Hc36pQtdCHw6r5ahByQx4YLxlT8sfTuBahOLYGuZ7M8zpe1GNV41XwAYHsPoZnv8mNoyv7CL7/c9TkX97FwOQX+DN7pxhyz8ib7wiuHMD37re8Be8VzMw5s4WzV5+gqcuHyxY3Gjs12F9tYDD5FQ5az5i6ZZ5hS3yW8Cxto7kvjjtDszAT7rsX0T4yr2vIrJzJLWKyTgdsYnB6U+Z4aiC7BwfFXLNGCvCliY1xg1Fiyey09A68b7flf6GuEC3qOrIuShsFzsdKlVryf4VowdBQHUjDdUdpOkM1MUtG0MipOJzT13KjhNrkWfopm5hJfE0XPUBGMCtmH/9IBmV2JVuu8y0mKMc9LE/EMFdwwUVunWt5jnbXPAam1qV7bf0J3LBNyhqb1hPOk+hzeBzK4Vn8E+xTGzLHBjWRiZ3eFJp7Y/qB7oUfqDP1SJcv3h5CfasNjR0aGszDMD0Koe6KFfdeLOD60wlcuuuimhcXaN81a1Syrm/tlz1cEywmH8jTH/qzsFBjb7SEW44kurQ0zM7XsBDzbmLGcYcnhQcvMzh/0y4/Gy31i+Jl2ct1cfKrZ/2U07HSccz6KScZXLeTNCwP5496i+Vv+dSbPTRQ+ZYZ6jOqeMtjq8zw/6EGlucFSYNaQiQKB6gl/gF7fYDEDFhVJQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Screenshot of underlined text.",
          "title": "Screenshot of underlined text.",
          "src": "/static/d70ee6de277623e1c5c8234088879634/d38a6/text-underline.png",
          "srcSet": ["/static/d70ee6de277623e1c5c8234088879634/12f09/text-underline.png 148w", "/static/d70ee6de277623e1c5c8234088879634/e4a3f/text-underline.png 295w", "/static/d70ee6de277623e1c5c8234088879634/d38a6/text-underline.png 376w"],
          "sizes": "(max-width: 376px) 100vw, 376px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Without the transition animation, the call to action links are a bit jarring going from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scale(1)`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scale(1.1)`}</code>{` on hover. So I changed it to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scale(1.05)`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`prefers-reduced-motion`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` reduce`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`animation-play-state`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` paused `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`scroll-behavior`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` auto `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`a`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-decoration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` underline`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`.blog__more-link a`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`text-decoration`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` none`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`.blog__more-link a:hover`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transform`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`scale`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`1.05`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`.hero__cta a`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`text-decoration`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` none`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`.hero__cta a:hover`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transform`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`scale`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`1.05`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <img {...{
      "className": "center",
      "src": "https://media.giphy.com/media/jtjBSsS5KPvtCCNqYn/giphy.gif",
      "alt": ""
    }}></img>
    <h3>{`How to test on a mac`}</h3>
    <p>{`This setting is primarily available on macOS.`}</p>
    <ol>
      <li parentName="ol">{`Go to System Preferences`}</li>
      <li parentName="ol">{`Go to Accessibility`}</li>
      <li parentName="ol">{`Go to Display`}</li>
      <li parentName="ol">{`Check “Reduce Motion”`}</li>
    </ol>
    <p>{`Easy Peasy! As soon as this post is published, you should be able to test this on my blog!`}</p>
    <h2>{`Creating a user controlled option to reduce motion`}</h2>
    <p><a parentName="p" {...{
        "href": "https://andy-bell.design/wrote/create-a-user-controlled-dark-or-light-mode/"
      }}>{`Andy Bell’s dark mode post`}</a>{` inspired me to add the user-controlled option. We want to have the user’s preferences take priority. We also want to account for people who don’t have these settings available to them.`}</p>
    <p>{`Here’s how we are going to do this:`}</p>
    <ol>
      <li parentName="ol">{`Create a checkbox with the label “reduce motion.”`}</li>
      <li parentName="ol">{`Add a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`checked`}</code>{` state and a method to toggle that state in my Gatsby App.`}</li>
      <li parentName="ol">{`Use that state to control a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`data-user-reduced-motion`}</code>{` attribute.`}</li>
      <li parentName="ol">{`Apply the CSS using the above attribute.`}</li>
      <li parentName="ol">{`Store that in `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`localStorage`}</code>{`, so we preserve the user settings.`}</li>
    </ol>
    <h3>{`Creating a `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`<ReduceToggle />`}</code>{` Component`}</h3>
    <p>{`This component is an HTML checkbox with a label. As a disclaimer, I am using a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`class`}</code>{` component and not hooks. I still like writing classes sometimes, and it was easier for my thought process. Keep an eye out for a hook version!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <p>{`The only thing I’ve done here is to create a checkbox input with an `}<a parentName="p" {...{
        "href": "/blog/introduction-accessible-labeling"
      }}>{`associated form label`}</a>{`. Something you may have noticed is that instead of for, React uses `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`htmlFor`}</code>{`.`}</p>
    <p>{`After that, I place it in my `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Header />`}</code>{` component above the menu. I’ll worry about styling finesse later; I know it’ll break my layout, and that’s ok. We are only worried about functionality right now.`}</p>
    <h3>{`Adding in the states`}</h3>
    <p>{`We want to go ahead and add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`checked`}</code>{` state to our constructor.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token function"
            }}>{`constructor`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`props`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`super`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`props`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`state `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      checked`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <p>{`Now we are going to add that state to the checkbox itself.`}</p>
    {
      /*prettier-ignore*/
    }
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` checked `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`state`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`          `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`checked`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`checked`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span></span>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <p>{`Next, we want to add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`toggleChecked`}</code>{` method for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onChange`}</code>{` event.`}</p>
    {
      /*prettier-ignore*/
    }
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`toggleChecked`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token parameter"
            }}>{`event`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setState`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` checked`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` event`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`target`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`checked `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checked `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`checked`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`checked`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
`}<span parentName="span" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`          `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`onChange`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token keyword"
                }}>{`this`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`toggleChecked`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span></span>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <p>{`I always like to double-check that the state is working using `}<a parentName="p" {...{
        "href": "https://github.com/facebook/react-devtools"
      }}>{`React Developer Tools`}</a>{`. To do this:`}</p>
    <ul>
      <li parentName="ul">{`I inspect the element`}</li>
      <li parentName="ul">{`Go to the React Tab`}</li>
      <li parentName="ul">{`Find the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ReduceToggle`}</code>{` component`}</li>
      <li parentName="ul">{`Ensure the state is working correctly!`}</li>
    </ul>
    <img {...{
      "className": "center",
      "src": "https://media.giphy.com/media/d83SPyGS9K9Pm6GQhw/giphy.gif",
      "alt": ""
    }}></img>
    <p>{`Now we know the state is working. Let’s toggle the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-user-reduced-motion`}</code>{` attribute value on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`documentElement`}</code>{`. I’m going to add that in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`componentDidUpdate`}</code>{` lifecycle method.`}</p>
    {
      /*prettier-ignore*/
    }
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token function"
            }}>{`componentDidUpdate`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` checked `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`state`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`checked`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      document`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`documentElement`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'data-user-reduced-motion'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`else`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      document`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`documentElement`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'data-user-reduced-motion'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`toggleChecked`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`event`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` event`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`target`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`checked `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checked `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`checked`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`checked`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onChange`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`toggleChecked`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <img {...{
      "className": "center",
      "src": "https://media.giphy.com/media/gIrdrOgahVAwaRsOW0/giphy.gif",
      "alt": ""
    }}></img>
    <h3>{`Adding CSS to the `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`data-user-reduced-motion`}</code></h3>
    <p>{`Word of warning. It’s tempting to jump into the CSS and copy and paste all the things. I recommend doing this one step at a time. I made the mistake of trying to do it all at once and spent a lot more time debugging than I wanted to. So first let’s go back to the goals of what we want.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We want user preference to rule over system preferences. We’ll add the system preferences as a `}<strong parentName="p">{`progressive enhancement`}</strong>{`.`}</p>
    </blockquote>
    <p>{`Gatsby is a Static Site Generator, so if JavaScript doesn’t load, most of my static site should load. However, if JavaScript doesn’t load, we want to fallback on system preferences when the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-user-reduced-motion`}</code>{` attribute doesn’t exist. So we are going to add a little bit to the query that we did in the first section about the media query itself. So we are using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:not()`}</code>{` CSS pseudo-class to do that.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@media`}</span>{` screen `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`and`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token property"
            }}>{`prefers-reduced-motion`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` reduce`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`:root:not([data-user-reduced-motion]) *`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`animation-play-state`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` paused `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`scroll-behavior`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` auto `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`:root:not([data-user-reduced-motion]) a`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-decoration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` underline`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`:root:not([data-user-reduced-motion]) .blog__more-link a`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-decoration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`:root:not([data-user-reduced-motion]) .blog__more-link a:hover`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1.05`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`:root:not([data-user-reduced-motion]) .hero__cta a`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-decoration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`:root:not([data-user-reduced-motion]) .hero__cta a:hover`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1.05`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Then we add the CSS `}<strong parentName="p">{`outside`}</strong>{` the query for if the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-user-reduced-motion="true"`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`:root[data-user-reduced-motion='true'] *`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`animation-play-state`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` paused `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`scroll-behavior`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` auto `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`:root[data-user-reduced-motion='true'] a`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-decoration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` underline`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`:root[data-user-reduced-motion='true'] .blog__more-link`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-decoration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 12px 14px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`:root[data-user-reduced-motion='true'] .blog__more-link:hover`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1.05`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`:root[data-user-reduced-motion='true'] .hero__cta__link`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-decoration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 12px 14px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`:root[data-user-reduced-motion='true'] .hero__cta__link:hover`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scale`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1.05`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`To test, I did the following:`}</p>
    <ol>
      <li parentName="ol">{`Turned off any reduce motion settings on macOS`}</li>
      <li parentName="ol">{`With the Reduce Toggle unchecked, ensure that all animations are still there.`}</li>
      <li parentName="ol">{`Check the Reduce Toggle Checkbox and see that all the CSS changes to reduce motion are working.`}</li>
      <li parentName="ol">{`In the element inspector, go to the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<html>`}</code>{` document and find the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`data-user-reduced-motion`}</code>{`. Remove that attribute. Here we are simulating that that attribute never loaded.`}</li>
      <li parentName="ol">{`Go to the system preferences and check Reduce Motion. We should have the CSS changes for reduced motion!`}</li>
    </ol>
    <h3>{`Adding `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`localStorage`}</code></h3>
    <p>{`Now that we have that working, we want to start playing with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`localStorage`}</code>{`. We want to preserve the user’s preferences for the future. Choosing your settings every time you visit isn’t the best user experience. If you don’t know what `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`localStorage`}</code>{` is, I would recommend pausing here and glancing over the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage"
      }}>{`docs`}</a>{`. If you like video examples, take a look at `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=YL1F4dCUlLc"
      }}>{`Wes Bos’s JS30 tutorial`}</a>{`.`}</p>
    <p>{`The first thing we are going to do is set the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`localStorage`}</code>{` on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`componentDidMount`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token function"
            }}>{`componentDidMount`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`let`}</span>{` reduceMotionOn `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` localStorage`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`getItem`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'reduceMotionOn'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    console`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reduceMotionOn`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`// if we haven't been to the site before`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`// this will return null`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// All other code stuff`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <p>{`Now what we want to do is create a default localStorage state for the user if the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reduceMotionOn`}</code>{` is null. I’m going to set that to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token function"
            }}>{`componentDidMount`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`let`}</span>{` reduceMotionOn `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` localStorage`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`getItem`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'reduceMotionOn'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`// Just a way to get around localStorage being`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`// stored as a string and not a bool`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`typeof`}</span>{` reduceMotionOn `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'string'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      reduceMotionOn `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`JSON`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`parse`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reduceMotionOn`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reduceMotionOn `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`null`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      localStorage`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setItem`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'reduceMotionOn'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// All other code stuff`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <p>{`The last thing I am going to do upon mounting the component is set the state in the app. I want to ensure that my app’s is the same as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`localStorage`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`componentDidMount`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` reduceMotionOn `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` localStorage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'reduceMotionOn'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`typeof`}</span>{` reduceMotionOn `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'string'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      reduceMotionOn `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reduceMotionOn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reduceMotionOn `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      localStorage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'reduceMotionOn'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setState`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` checked`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` reduceMotionOn `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// All other code stuff`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <p>{`Go to Application > Local Storage in Chrome Dev Tools (Storage > Local Storage in Firefox). Then, clear the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reduceMotionOn`}</code>{` storage. Upon refresh, you should see that the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reduceMotionOn`}</code>{` is false. If you go to React Dev Tools and go to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<ReduceToggle />`}</code>{` component, you’ll find that the checked state matches the reduceMotionOn localStorage item.`}</p>
    <p>{`That’s not all! We have to toggle the localStorage in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`toggleChecked`}</code>{` method in the React component.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ReduceToggle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// All other code stuff`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`toggleChecked`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`event`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    localStorage`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setItem`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'reduceMotionOn'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` event`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`target`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`checked`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checked`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` event`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`target`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`checked `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`checkbox`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`htmlFor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`reduce-motion`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Reduce Motion`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` ReduceToggle`}</code></pre></div>
    <p>{`Now if I check Reduce motion and leave the site, my user-controlled preferences are preserved!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Thanks for joining me while I added a progressively enhanced accessibility feature to my blog! I hope you learned something along the way. Shout out to Andy for inspiring me to write this post!`}</p>
    <p>{`These are the key takeaways from this post, regardless of the framework that you use:`}</p>
    <ol>
      <li parentName="ol">{`Be careful with what you animate and provide options for those who have vestibular disorders.`}</li>
      <li parentName="ol">{`User-controlled > System Preferences`}</li>
      <li parentName="ol">{`Have system preferences for progressive enhancements`}</li>
      <li parentName="ol">{`Use `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`localStorage`}</code>{` to your benefit so that the user settings are preserved!`}</li>
    </ol>
    <p>{`If you’d like to play around with this, I’ve made a CodeSandbox for you!`}</p>
    <iframe src="https://codesandbox.io/embed/laughing-sutherland-76xj6?fontsize=14" title="Testing Reducing Motion" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`Cheers! Have a great week!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      