import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Happy Tax day (at least if you’re American)!`}</p>
    <p>{`My `}<a parentName="p" {...{
        "href": "/blog/web-accessibility-testing-process"
      }}>{`last post`}</a>{` was about my accessibility testing process. However, this week, I wanted to examine automated testing. I have my initial process of what I do while I am working and what I make sure I manually test. I also wrote about all the things `}<a parentName="p" {...{
        "href": "/blog/4-things-always-manually-test"
      }}>{`I manually test`}</a>{`.`}</p>
    <p>{`These days Continuous Integration is essential for large teams. To your surprise, I haven’t configured accessibility CLI tools for my projects. I was the primary front end most of the time and I always manually test things out of habit, so it never felt necessary.`}</p>
    <p>{`It’s time to call myself out. I absolutely `}<strong parentName="p">{`should`}</strong>{` be integrating these into my tests into my projects. I used to be the only front end developer, and I took it for granted. I am now on a team where `}<strong parentName="p">{`I am the accessibility expert`}</strong>{`, but I have other front end developers on the team. I review many pull requests, but it would be way easier if Continuous Integration could catch new errors. Isn’t that the point of Continuous Integration? To catch things that we miss? To make our lives easier?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Heads up. This is going to be a “learn with me” post.`}</p>
    </blockquote>
    <p>{`I’m going to be going over 2 of the CLI tools I am familiar with - pa11y and aXe-cli. Feel free to `}<a parentName="p" {...{
        "href": "https://twitter.com/LittleKope"
      }}>{`Tweet at me`}</a>{` any tools you would like to check out.`}</p>
    <h2>{`Pa11y`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/pa11y/pa11y"
      }}>{`Pa11y`}</a>{` is the first accessibility testing tool I used a couple of years back. The first time I tried it, it was a gulp package. Admittedly, I never did any of the configuring because at the time I found the tool to be too restrictive.`}</p>
    <p>{`I’m coming at this fresh, so I am going to go through installing it in this blog post and see how I like it.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install pa11y --save`}</code></p>
    <p>{`Now I am going to create an npm script to run pa11y. In my `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`package.json`}</code>{` file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token property"
          }}>{`"scripts"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"test-a11y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"pa11y ./index.html"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Then I went to my command line and typed `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`npm run test-a11y`}</code>{` and got errors. I got a SyntaxError that I remember seeing 2 years ago, and I happened to remember that it was a node version issue.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` run test-a11y

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` egghead-focus-event@1.0.0 test-a11y /Users/lkopacz/Sites/egghead-focus-event
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` pa11y ./index.html

/Users/lkopacz/Sites/egghead-focus-event/node_modules/pa11y/bin/pa11y.js:92
async `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function-name function"
          }}>{`runProgram`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      ^^^^^^^^`}</code></pre></div>
    <p>{`When I checked what node version I was using, I learned that somehow, I had switched from node v.8 to node v.6. Embarrassing to admit I didn’t catch this and I was on an older version of node.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`nvm use v8.9.1`}</code></p>
    <p>{`Now the script appears to run properly! I ran it and got an accessibility error.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` run test-a11y
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` egghead-focus-event@1.0.0 test-a11y /Users/lkopacz/Sites/egghead-focus-event
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` pa11y ./index.html


Welcome to Pa11y

 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` Running Pa11y on URL file:///Users/lkopacz/Sites/egghead-focus-event/index.html

Results `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` URL: file:///Users/lkopacz/Sites/egghead-focus-event/index.html

 • Error: The html element should have a lang or xml:lang attribute `}<span parentName="code" {...{
            "className": "token function"
          }}>{`which`}</span>{` describes the language of the document.
   ├── WCAG2AA.Principle3.Guideline3_1.3_1_1.H57.2
   ├── html
   └── `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`html `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"no-js"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`--`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`endif`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`--`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`head`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`met`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`/html`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` Errors`}</code></pre></div>
    <p>{`Welp that’s embarrassing. However, that’s precisely why this exists. Going to fix this and see what happens.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`no-js`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`lang`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`en`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- MOAR HTML-->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Now let’s run that test again and see what happens.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` run test-a11y

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` egghead-focus-event@1.0.0 test-a11y /Users/lkopacz/Sites/egghead-focus-event
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` pa11y ./index.html


Welcome to Pa11y

 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` Running Pa11y on URL file:///Users/lkopacz/Sites/egghead-focus-event/index.html

No issues found`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span></code></pre></div>
    <p>{`Well, that was easy to set up! So far, I like pa11y. I would love to play around more with this and look into all their other tools, such as the pa11y dashboard. However, the CLI seems to be the most beneficial for the point of this blog post.`}</p>
    <h2>{`aXe-cli`}</h2>
    <p>{`Deque Systems, a leading accessibility firm, developed the `}<a parentName="p" {...{
        "href": "https://github.com/dequelabs/axe-cli"
      }}>{`aXe-cli`}</a>{` tool. I haven’t checked out many Deque tools, which is pretty shocking since they are leaders in this space. I am going to install the module the same way I did above with pa11y. This time I am going to test it against `}<a parentName="p" {...{
        "href": "https://github.com/lkopacz/egghead-form-labels"
      }}>{`my form labels lesson code`}</a>{`. This particular lesson has accessibility errors because I fixed them in `}<a parentName="p" {...{
        "href": "https://egghead.io/lessons/html-5-creating-accessible-forms-with-associated-form-labels"
      }}>{`the video`}</a>{`. I know there are form label errors.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install axe-cli --save`}</code></p>
    <p>{`Then I am going to create the same npm script that I did with the pa11y task.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token property"
          }}>{`"scripts"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"test-a11y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"axe ./index.html"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`When I ran the command, this is what I received:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` run test-a11y

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` egghead-30-second@1.0.0 test-a11y /Users/lkopacz/Sites/egghead-form-labels
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` axe ./index.html

Running axe-core `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3.2`}</span>{`.2 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` chrome-headless

Testing http://./index.html `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`. please wait, this may take a minute.
axe-webdriverjs deprecated Error must be handled as the first argument of axe.analyze. See: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#83 node_modules/axe-cli/lib/axe-test-urls.js:85:8`}</span>{`

  Violation of `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"document-title"`}</span>{` with `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` occurrences`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
    Ensures each HTML document contains a non-empty `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` element. Correct invalid elements at:
     - html
    For details, see: https://dequeuniversity.com/rules/axe/3.2/document-title

  Violation of `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"html-has-lang"`}</span>{` with `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` occurrences`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
    Ensures every HTML document has a lang attribute. Correct invalid elements at:
     - html
    For details, see: https://dequeuniversity.com/rules/axe/3.2/html-has-lang

  Violation of `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"landmark-one-main"`}</span>{` with `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` occurrences`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
    Ensures the document has only one main landmark and each iframe `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` the page has at `}<span parentName="code" {...{
            "className": "token function"
          }}>{`most`}</span>{` one main landmark. Correct invalid elements at:
     - html
    For details, see: https://dequeuniversity.com/rules/axe/3.2/landmark-one-main

  Violation of `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"page-has-heading-one"`}</span>{` with `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` occurrences`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
    Ensure that the page, or at least one of its frames contains a level-one heading. Correct invalid elements at:
     - html
    For details, see: https://dequeuniversity.com/rules/axe/3.2/page-has-heading-one

`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` Accessibility issues detected.

Please note that only `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`% to `}<span parentName="code" {...{
            "className": "token number"
          }}>{`50`}</span>{`% of all accessibility issues can automatically be detected.
Manual testing is always required. For `}<span parentName="code" {...{
            "className": "token function"
          }}>{`more`}</span>{` information see:
https://dequeuniversity.com/curriculum/courses/testingmethods`}</code></pre></div>
    <p>{`Ok. So, at first glance I like some things a ton. Mostly because of the disclaimer at the end.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Please note that only 20% to 50% of all accessibility issues can automatically be detected. Manual testing is always required.`}</p>
    </blockquote>
    <h2>{`Comparing the two`}</h2>
    <p>{`I like both these tools. Let’s first go to the Pros and Cons of both of them!`}</p>
    <h3>{`pa11y`}</h3>
    <p>{`PROS:`}</p>
    <ol>
      <li parentName="ol">{`Setting it up was pretty simple.`}</li>
      <li parentName="ol">{`They tell you where the offending code is in the HTML`}</li>
    </ol>
    <p>{`CONS:`}</p>
    <ol>
      <li parentName="ol">{`The only reason I knew how to debug the initial error is because I remembered it from a few years back. It `}<strong parentName="li">{`does`}</strong>{` have it on its documentation, but I missed it. Deque’s requirements were way higher up on the README.md`}</li>
      <li parentName="ol">{`The error messages aren’t super verbose. Sometimes this is trickier for newcomers`}</li>
    </ol>
    <h3>{`aXe-cli`}</h3>
    <p>{`PROS:`}</p>
    <ol>
      <li parentName="ol">{`As I stated above, I love the disclaimer that you `}<strong parentName="li">{`should`}</strong>{` be manually testing to catch other issues. Please see my `}<a parentName="li" {...{
          "href": "/blog/web-accessibility-testing-process"
        }}>{`last post`}</a>{` about my accessibility testing process. I also have a checklist of `}<a parentName="li" {...{
          "href": "/blog/4-things-always-manually-test"
        }}>{`what I manually test`}</a>{`.`}</li>
      <li parentName="ol">{`I also like that it gives you some links to the rules that failed.`}</li>
    </ol>
    <p>{`CONS:`}</p>
    <ol>
      <li parentName="ol">{`What I like better about pa11y is that it tells you exactly where the offending elements are. Note that I am not saying it’s not possible. I’ve only tested the most basic command I could.`}</li>
    </ol>
    <h2>{`Conclusion`}</h2>
    <p>{`Well, I got started in automated accessibility testing late, but I am a convert. If you noticed, I forgot a couple of things `}<strong parentName="p">{`even as an expert`}</strong>{`. We are not perfect. If you’re working with teams newer to accessibility, it’s a powerful tool. It ensures that other team members do not introduce new issues or errors. I think both of these tools are super solid out of the box. I’m planning on going through both their documentation more and seeing how I can further configure it!`}</p>
    <p>{`For now, I am planning on implementing pa11y on my projects. I prefer that one out of the box for my team because I can explain to my team why the errors are occurring. I want to explore aXe-cli more because I think the verbosity was much clearer. I’m sure with more configuration, I’d be able to have aXe work more to my liking.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      