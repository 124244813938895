import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hey friends! Today’s blog post comes to you from my Patreon folks’ poll. It’s a follow up from one of my previous posts about `}<a parentName="p" {...{
        "href": "/blog/beginning-demystify-aria"
      }}>{`Demystifying ARIA`}</a>{`. ARIA can be utterly mysterious and daunting, but it’s handy. One of my favorite ways to use it is to communicate state to screen readers.
I’m seeing a positive trend in the a11y community. Instead of using classes to style elements, a11y folks are styling by using ARIA attributes in the CSS. This helps enforce accessibility standards and prevent people from cheating accessibility. The attributes I’ll be going over today are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hidden`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-current`}</code>{`.`}</p>
    <h2>{`The `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{` attribute`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{` is one of the few aria attributes that does not have an HTML5 equivalent. Please tweet me if I am wrong, but at the time of writing, I believe this to be correct. In the `}<a parentName="p" {...{
        "href": "/blog/javascript-accessibility-accordions"
      }}>{`Accessible Accordion post`}</a>{`, I used `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{` to convey the section’s state.`}</p>
    <p>{`Why is this helpful? Let’s take the accordion example that I went through in the blog post that I linked in the previous paragraph:`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Accordion - aria-expanded" src="//codepen.io/littlekope0903/embed/OYqNOd/?height=450&theme-id=dark&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/littlekope0903/pen/OYqNOd/">
    Accordion - aria-expanded
  </a>{' '}
  by Lindsey Kopacz (
  <a href="https://codepen.io/littlekope0903">@littlekope0903</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p><em parentName="p">{`Note: I changed the code in the above sample to have the CSS tied more to the attributes and less to CSS classes.`}</em></p>
    <p>{`If you go through this on a screen reader, you’ll get something like this:`}</p>
    <video controls>
  <source src="/971d407bd2a312272ca0bcc8c3cb0e08/aria-expanded.mov" type="video/mp4" />
    </video>
    <p>{`Imagine if we took off the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{` attribute which I did in a `}<a parentName="p" {...{
        "href": "https://codepen.io/littlekope0903/pen/GazpwN/"
      }}>{`separate CodePen`}</a>{`.`}</p>
    <p><em parentName="p">{`Note: I kept most of the original code in here. That way, I could demonstrate the visual opening and closing. Additionally, it shows how it doesn’t work for a screen reader user.`}</em></p>
    <video controls>
  <source src="/504fd8e89152823c46a4a4c7049e1e8d/no-aria-expanded.mov" type="video/mp4" />
    </video>
    <p>{`Do you notice how when I open the button, it doesn’t communicate whether the section is open or closed? This is critical for a screen reader user to know what is going on. Withholding verbal communication of state is confusing for screen reader users.`}</p>
    <p>{`Anytime we hide content that expands upon user action, use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{`. When you use JavaScript to toggle it, it becomes a better user experience.`}</p>
    <h2>{`The `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`hidden`}</code>{` attribute`}</h2>
    <p>{`If you saw my notes above, you may have seen that I changed up the code. Previously, I had the accordion using an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{` attribute (more on that later). Upon the button click, I would toggle the value to be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{`. The reason for that is because `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hidden`}</code>{` is the HTML equivalent of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`display: none`}</code>{`.`}</p>
    <p>{`When I started with accessibility, the motivation to hide content from screen readers baffled me. Why would I want to exclude them from content?? I used to be resistant to `}<strong parentName="p">{`ever`}</strong>{` using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hidden`}</code>{`, but the reality is sometimes it’s necessary. With some forms of content, we don’t want to expose content to a user until we interact with it. For example, with modals and accordions, we only want to see what’s inside if they are open.`}</p>
    <h3>{`When to use `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code></h3>
    <p>{`There’s one significant difference between `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hidden`}</code>{`:`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`aria-hidden`}</code>{` only hides elements from screen readers, while `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`hidden`}</code>{` hides from everyone.`}</p>
    </blockquote>
    <p>{`I ask myself if there are HTML elements that are primarily for decoration. If so, I might want to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{` to hide it from screen readers but not from visually-abled users.`}</p>
    <p>{`One of my favorite reason’s to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{` is to prevent repetition. This, for example, is the Home button for Twitter:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`js-nav js-tooltip js-dynamic-tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Icon Icon--home Icon--large`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Icon Icon--homeFilled Icon--large u-textUserColor`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-hidden`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`true`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Home`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`u-hiddenVisually a11y-inactive-page-text`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Home`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`u-hiddenVisually a11y-active-page-text`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    Home, current page.
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`u-hiddenVisually hidden-new-items-text`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    New Tweets available.
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`In this instance, the screen reader announces one of the “a11y page texts” based on the context of the page. I want you to take note of this line of code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`text`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-hidden`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`true`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Home`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`This is the one that is actually on the screen. We have the dynamic options for the screen reader like “Home,” “Home, current page,” and “Load new Tweets.” We don’t need that context on the Home button if we can see the visual cues.`}</p>
    <p>{`With that being said, my rule is if we are toggling states, I use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hidden`}</code>{`. If there’s duplicate or unnecessary content, I’ll use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{`.`}</p>
    <h2>{`The `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`aria-current`}</code>{` attribute`}</h2>
    <p>{`Fun fact, this one is totally new to me. I didn’t learn about this until I saw `}<a parentName="p" {...{
        "href": "https://twitter.com/ericwbailey/"
      }}>{`Eric Bailey`}</a>{`’s tweet about the `}<a parentName="p" {...{
        "href": "https://a11yproject.com/"
      }}>{`a11y project`}</a>{` redesign:`}</p>
    <blockquote className="twitter-tweet" data-lang="en">
  <p lang="en" dir="ltr">
    A little detail I&#39;m proud of for the upcoming{' '}
    <a href="https://twitter.com/A11YProject?ref_src=twsrc%5Etfw">
      @A11YProject
    </a>{' '}
    redesign is using `[aria-current]` as a styling hook for our table of
    contents component. Instead of using a &quot;.is-current&quot; class or the
    like, we&#39;re typing visual appearance to semantic state for where you are
    on the page.{' '}
    <a href="https://t.co/tt3k3MYEuk">pic.twitter.com/tt3k3MYEuk</a>
  </p>
  &mdash; Eric Bailey (@ericwbailey) <a href="https://twitter.com/ericwbailey/status/1134863254310797312?ref_src=twsrc%5Etfw">June 1, 2019</a>
    </blockquote>
    <p>{`The best part about the web and accessibility practices is I learn something new every day. After seeing this tweet, I found `}<a parentName="p" {...{
        "href": "https://tink.uk/using-the-aria-current-attribute/"
      }}>{`Léonie’s post on aria-current`}</a>{`. Something they pointed out is that we predominately use CSS to show the “current” element visually. In my Drupal days, most themes had an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.is-active`}</code>{` class if you were on a menu item that reflected the current URL.`}</p>
    <p>{`As Léonie said, the problem with this approach is CSS is mostly visual. Meaning (with an exception) 0 of the styling is exposed to screen readers. Using aria-current helps ensure that we communicate the context to screen reader users. According to `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.1/#aria-current"
      }}>{`WAI-ARIA 1.1`}</a>{`, there are a few values that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-current`}</code>{` could take on:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`page`}</strong>{` for a link within a set of pagination links, the current page as represented in the navigation, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`step`}</strong>{` for a step-based process.`}</li>
      <li parentName="ul"><strong parentName="li">{`date`}</strong>{` for a current date.`}</li>
      <li parentName="ul"><strong parentName="li">{`time`}</strong>{` for a current time.`}</li>
    </ul>
    <p>{`Additionally, this should be only one element in a set of elements can have an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-current`}</code>{` value. Otherwise, it will confuse the screen reader!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`I plan to have more blog posts about ARIA and state in the future. I hope this helped you demystify a few more aria attributes.`}</p>
    <p>{`Cheers! Have a great week!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      