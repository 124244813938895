import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Content Warning: This blog post contains gifs.`}</em></p>
    <p>{`When I first wrote my post about `}<a parentName="p" {...{
        "href": "/blog/a11y-js-seemingly-unconventional-romance"
      }}>{`JavaScript and Accessibility`}</a>{`, I promised I would make it a series. I’ve decided to use my patreon to have votes on what my next blog post is. This topic won, and I’m finally getting more time to write about JavaScript!`}</p>
    <p>{`So this topic I am going to go into a deep dive on how to make accordions accessible! Our focus is:`}</p>
    <ul>
      <li parentName="ul">{`Accessing the accordion with a keyboard`}</li>
      <li parentName="ul">{`Screen reader support`}</li>
    </ul>
    <h2>{`HTML Structure`}</h2>
    <p>{`I did a few pieces of research about the HTML structure. I read the `}<a parentName="p" {...{
        "href": "https://a11yproject.com/patterns#accordions-and-tabs"
      }}>{`a11y project`}</a>{`’s link to `}<a parentName="p" {...{
        "href": "https://scottaohara.github.io/a11y_accordions/"
      }}>{`Scott O’Hara’s Accordion code`}</a>{`. I also read `}<a parentName="p" {...{
        "href": "https://www.heydonworks.com/article/aria-controls-is-poop"
      }}>{`Don’s take about aria-controls`}</a>{` - TL;DR he thinks they’re poop. I couldn’t escape reading the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-practices/examples/accordion/accordion.html"
      }}>{`WAI-ARIA Accordion example`}</a>{` as they set a lot of the standards. My hope is with all the information about what’s ideal, I can help talk through why everything is important here. It’s easy to get overwhelmed, and I’m here to help!`}</p>
    <p>{`So if you read my post `}<a parentName="p" {...{
        "href": "/blog/3-simple-tips-improve-keyboard-accessibility"
      }}>{`3 Simple Tips to Improve Keyboard Accessibility`}</a>{`, you may recall my love for semantic HTML.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If you need JavaScript for accessibility, semantic HTML makes your job significantly easier.`}</p>
    </blockquote>
    <p>{`Many of the examples I found use semantic button elements for the accordion headings. Then the examples used div tags as siblings. Below is how my code starts:`}</p>
    <iframe height="448" style={{
      "width": "100%"
    }} scrolling="no" title="Accordion - Step 1" src="//codepen.io/littlekope0903/embed/RmRVvN/?height=448&theme-id=dark&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/RmRVvN/'>Accordion - Step 1</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h3>{`Adding the ARIA attributes`}</h3>
    <p>{`I wrote that ARIA is not a replacement for semantic HTML in a `}<a parentName="p" {...{
        "href": "/blog/beginning-demystify-aria"
      }}>{`previous post`}</a>{`. New HTML features that come out are replacing ARIA all the time. In an ideal world, I would use the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details"
      }}>{`details element`}</a>{`. Unfortunately, according to the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details#Browser_compatibility"
      }}>{`Browser Compatibility Section`}</a>{`, there is no support for Edge and IE11. Until browser support improves, I’ll be sticking to the “old fashioned” way of doing it. I’ll be adding ARIA for the context we need. I’m looking forward to seeing the compatibility expand to Edge!`}</p>
    <p>{`First, I am going to add some `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{` attributes to the div to indicate the `}<strong parentName="p">{`state`}</strong>{` of the accordion content. If the collapsed element is `}<strong parentName="p">{`closed`}</strong>{`, we want to hide that content from the screen reader. Can you imagine how annoying it would be to read through the content you are not interested in?`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}><span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <div id="accordion-section-1">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <div id="accordion-section-1" aria-hidden="true">
`}</span>{`...
...
`}<span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <div id="accordion-section-2">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <div id="accordion-section-2" aria-hidden="true">
`}</span>{`...
...
`}<span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <div id="accordion-section-3">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <div id="accordion-section-3" aria-hidden="true">`}</span></code></pre></div>
    <p>{`The next thing we do is ensure that we have an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{` attribute to the button. When we are on the button, it tells us if something is expanded or collapsed.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}><span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <button id="accordion-open-1">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <button id="accordion-open-1" aria-expanded="false">
`}</span>{`...
...
`}<span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <button id="accordion-open-2">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <button id="accordion-open-2" aria-expanded="false">
`}</span>{`...
...
`}<span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <button id="accordion-open-3">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <button id="accordion-open-3" aria-expanded="false">`}</span></code></pre></div>
    <p>{`When it comes to ARIA for me, less is more. I am going to leave it at that and use JavaScript in a future section to toggle the states of the ARIA attributes.`}</p>
    <iframe height="452" style={{
      "width": "100%"
    }} scrolling="no" title="Accordion - Step 2" src="//codepen.io/littlekope0903/embed/RmRLqw/?height=452&theme-id=dark&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/RmRLqw/'>Accordion - Step 2</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`Adding Some Styling`}</h2>
    <p>{`I’m not going to focus too much on the CSS specifics. If you need a CSS resource, Ali Spittel’s post `}<a parentName="p" {...{
        "href": "https://dev.to/aspittel/css-from-zero-to-hero-3o16"
      }}>{`CSS: From Zero to Hero`}</a>{` and Emma Wedekind’s `}<a parentName="p" {...{
        "href": "https://dev.to/emmawedekind/css-specificity-1kca"
      }}>{`CSS Specificity`}</a>{` post are great.`}</p>
    <p>{`First, I add classes to the divs and the buttons for good measure.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}><span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <button id="accordion-open-1" aria-expanded="false">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <button id="accordion-open-1" class="accordion__button" aria-expanded="false">
`}</span><span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   Section 1
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` </button>
`}</span><span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <div id="accordion-section-1" aria-hidden="true">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <div id="accordion-section-1" class="accordion__section" aria-hidden="true">`}</span></code></pre></div>
    <p>{`Then I add a bunch of styling to the buttons. I wrote this CodePen with SCSS.`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Accordion - Step 3 (Styling)" src="//codepen.io/littlekope0903/embed/NVrway/?height=450&theme-id=dark&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/NVrway/'>Accordion - Step 3 (Styling)</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`(Quick note: for the triangles on the iframe, I used the `}<a parentName="p" {...{
        "href": "https://css-tricks.com/snippets/css/css-triangle/"
      }}>{`CSS Triangle article`}</a>{` from CSS tricks.)`}</p>
    <p>{`I want to point out `}<strong parentName="p">{`explicitly`}</strong>{` this code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.accordion `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// previous styling`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`__button.expanded `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$purple`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$lavendar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`I want to specify what the button looks like when it was open. I like how it draws your eye and attention to the open section. Now that I see what they generally look like, I am going to add the styling to collapse them. Additionally, I’m adding some open styling.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}><span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` &__section {
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   border-left: 1px solid $purple;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   border-right: 1px solid $purple;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   padding: 1rem;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   background: $lavendar;
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   max-height: 0vh;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   overflow: hidden;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   padding: 0;
`}</span><span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` }
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` &__section.open {
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   max-height: 100vh;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   overflow: auto;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   padding: 1.25em;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   visibility: visible;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` }`}</span></code></pre></div>
    <p>{`Finally, let’s add some focus and hover styling for the buttons:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}><span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` $purple: #6505cc;
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` $dark-purple: #310363;
`}</span><span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` $lavendar: #eedbff;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}><span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` &__button {
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   position: relative;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   display: block;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   padding: 0.5rem 1rem;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   width: 100%;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   text-align: left;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   border: none;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   color: $purple;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   font-size: 1rem;
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   background: $lavendar;
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   &:focus,
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   &:hover {
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`     background: $dark-purple;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`     color: $lavendar;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`     &::after {
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`       border-top-color: $lavendar;
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`     }
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   }`}</span></code></pre></div>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Accordion - Step 4 - final styling" src="//codepen.io/littlekope0903/embed/mYEYWg/?height=450&theme-id=dark&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/mYEYWg/'>Accordion - Step 4 - final styling</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`A quick note: you could likely add styling by adding `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.accordion__button[aria-expanded="true"]`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.accordion__section[aria-hidden="false"]`}</code>{`. However, it is my personal preference using classes for styling and not attributes. Different strokes for different folks!`}</p>
    <h2>{`JavaScript toggling`}</h2>
    <p>{`Let’s now get to the fun part of toggling the accordion in an accessible way. First, I want to grab all the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.section__button`}</code>{` elements.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` accordionButtons `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__button'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Then I want to step through every element of the HTML collection that JavaScript returns.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`accordionButtons`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`button`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// returns <button id="accordion-open-1" class="accordion__button" aria-expanded="false">`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//    Section 1`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//  </button>`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//  <button id="accordion-open-2" class="accordion__button" aria-expanded="false">`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//    Section 2`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//  </button>`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//  <button id="accordion-open-3" class="accordion__button" aria-expanded="false">`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//    Section 3`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//  </button>`}</span></code></pre></div>
    <p>{`Then for each of those items, I want to toggle the class for the opening and closing for visual styling purposes. If you remember the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.open`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.expanded`}</code>{` classes that we added before, here is where we toggle them. I am going to use the number in the ids that match up with each other to get the corresponding section for that button.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`accordionButtons`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`button`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// This gets the number for the class.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// e.g. id="accordion-open-1" would be "1"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` number `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` button
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'id'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`split`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'-'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pop`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// This gets the matching ID. e.g. the`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// section id="accordion-section-1" that is underneath the button`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` associatedSection `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getElementById`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`accordion-section-`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`number`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Now we have the current value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`button`}</code>{` in the callback and the associated section. Now we can get to toggling classes!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'expanded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  associatedSection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'open'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Toggling classes is not all we want to do. We also want to toggle the aria attributes. From the previous section, aria attributes communicate `}<strong parentName="p">{`state`}</strong>{` to screen readers. Changing the classes shows what happened to a visual user, not to a screen reader. Next, I check if the button contains the class in one of those elements. If it does, I’ll swap the state for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}>{`button.addEventListener('click', () => {
`}<span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` button.classList.toggle('expanded')
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` associatedSection.classList.toggle('open')
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` if (button.classList.contains('expanded')) {
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`   console.log('open?')
`}<span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` }
`}</span>{`})`}</code></pre></div>
    <p>{`The conditional fires after we set the classes, and if the class has expanded, it is open! So this is where we want to use the states and communicate it’s open.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'expanded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  associatedSection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'open'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`contains`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'expanded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'aria-expanded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    associatedSection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'aria-hidden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'aria-expanded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    associatedSection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'aria-hidden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Now we can open and close the accordion with the spacebar or the enter key!`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Accordion - Step 5 - JavaScript Toggling" src="//codepen.io/littlekope0903/embed/qGNGyQ/?height=450&theme-id=dark&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/qGNGyQ/'>Accordion - Step 5 - JavaScript Toggling</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`Let’s test on our screen reader to be extra sure.`}</p>
    <video controls>
  <source src="/77971ddc15478a28d6a1c26ba9fb31a2/accordion-screenreader.mov" type="video/mp4" />
    </video>
    <p>{`When I go through the accordions headers without opening them, they do not read them in the section. That’s a good thing! When I open it, I’m able to read it.`}</p>
    <h2>{`Progressive Enhancement`}</h2>
    <p>{`Now, I know how much we all rely on JavaScript loading, particularly with all the frameworks we use. Now that we know the functionality, let’s refactor the code a bit. The goal is to ensure anyone can access the accordion if JavaScript is not enabled or the user has connectivity issues.`}</p>
    <p>{`My final touch is to`}</p>
    <ul>
      <li parentName="ul">{`Keep all the accordion sections open by default (Adding an `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`.open`}</code>{` class to the HTML sections)`}</li>
      <li parentName="ul">{`Remove the ‘open’ class once the JavaScript loads.`}</li>
      <li parentName="ul">{`Add all the aria attributes with JavaScript and remove that from the HTML`}</li>
    </ul>
    <p>{`I want to remove `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded="false"`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden="true"`}</code>{` from my buttons and sections, respectively. I also want to add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`open`}</code>{` class to the html, so it’s visually open by default.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}><span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <button id="accordion-open-1" class="accordion__button" aria-expanded="false">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <button id="accordion-open-1" class="accordion__button">
`}</span><span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{`   Section 1
`}<span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` </button>
`}</span><span parentName="code" {...{
            "className": "token deleted-sign deleted"
          }}><span parentName="span" {...{
              "className": "token prefix deleted"
            }}>{`-`}</span>{` <div id="accordion-section-1" class="accordion__section" aria-hidden="true">
`}</span><span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` <div id="accordion-section-1" class="accordion__section open">`}</span></code></pre></div>
    <p>{`I want to set those attributes and remove that class in the forEach loop of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`accordionButtons`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}>{`accordionButtons.forEach(button => {
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` button.setAttribute('aria-expanded', false);
`}</span><span parentName="code" {...{
            "className": "token unchanged"
          }}><span parentName="span" {...{
              "className": "token prefix unchanged"
            }}>{` `}</span>{` const expanded = button.getAttribute('aria-expanded');`}</span></code></pre></div>
    <p>{`Then I want to create an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`accordionsSections`}</code>{` variable and do two things:`}</p>
    <ul>
      <li parentName="ul">{`set the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`aria-hidden`}</code>{` attribute`}</li>
      <li parentName="ul">{`remove the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`.open`}</code>{` class.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` accordionSections `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__section'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

accordionSections`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`section`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  section`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'aria-hidden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  section`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`remove`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'open'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`We’re done! Remember, we haven’t removed any of the other code or event listeners. We are just adding all those attributes in with JavaScript.`}</p>
    <iframe height="452" style={{
      "width": "100%"
    }} scrolling="no" title="Accordion - Step 6 - Progressive Enhancement" src="//codepen.io/littlekope0903/embed/qGRQvg/?height=452&theme-id=dark&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/qGRQvg/'>Accordion - Step 6 - Progressive Enhancement</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`Conclusion`}</h2>
    <p>{`What did you think of this post? Did it help you? Are you excited for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<details>`}</code>{` element? Cheers! Have a great week!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      