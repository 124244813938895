import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If I ask anyone what accessibility means to them, usually the first thing that they can identify is “Adding alt text!” All you have to do is add alt tags to your images makes your site way more accessible, right? I love the enthusiasm to reduce accessibility errors, but I am about to give you an infuriating response. `}<strong parentName="p">{`It depends`}</strong>{`.`}</p>
    <p>{`Adding alt text may mean that you don’t get pesky errors about missing alternative text on an Accessibility Scan, but it doesn’t necessarily mean that your images have a better meaning. Sometimes an empty alt tag is actually what you need for a more accessible image. Are you envisioning the mind blown emoji and/or gif yet?`}</p>
    <p>{`That’s how I felt when I was learning about it, and it made me realize how much bad advice I had given to others about fixing alt text. Hopefully, my conscience is clear after this blog post! I used to think that every single image had to have alternative text, and didn’t really put much thought into how to write it. I would love to give some of my tips to not only help you avoid accessibility errors but to think more critically and strategically about how you are giving your visually impaired users the best experience possible.`}</p>
    <p>{`I have a few strategies to start writing better alternative text, I usually ask myself the below questions.`}</p>
    <h2>{`Is it decorative?`}</h2>
    <p>{`The first thing I want anyone to think about before they start diving into their CMS’s media gallery is not technical at all. It’s strategic. What is the `}<em parentName="p">{`purpose`}</em>{` of that image being there? If we took away that image for visually able users, would we still get the information we needed? The reason I mention this first is because this is relatively simple to ask yourself before you get started. Let’s start off with a practical example that lots of organization websites have - Biography pages.`}</p>
    <p>{`Let’s say we have a “Team” page on our site where we can click a link that directs us to the person’s page to read more information. We click into the CEO’s page and there’s a headshot of the CEO of the business. A lot of the time, the CEO’s photo doesn’t screw with your accessibility testing because you will see something in the alt text such as “Photo of the CEO, John Paul.” For headshots like this, this type of information is pretty useless and redundant to those with visual impairments. All the important information is about the CEO is likely right next to his photo. That would make this photo decorative, and therefore an empty alt="" would suffice.`}</p>
    <p>{`Another time where I can think about alternative text being empty is when we are using image icons. As a note, when I say image icons I do not mean using icon fonts like Font Awesome. Take the below example:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "99.32432432432432%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAClklEQVQ4y4VUS67TQBDM2bgAl+AsPIktV2CFhARbtkhPgAQ7JBIn7+Xl43wc5+dfxjN20dXOJE6EkkgVt8fT1d3VPdOpqgplacFfXUPsElyj3azVqO6hqnWftQ4dOoVhiG6vpwiCAEG/j7/drtrGGBypce/nJJGOcxWsRDCSZSkR9F2epbUKvjv5fg/Mkv6donSohVn+UIqzsXzWCtrEgZB9t8B9ubFScu3wqVvi4YfFdpdIuU8IBs/oBgMMRxM8v4wRxRs46nsMRIg/7PHpAxdtwnc/LXZJjkk4RzhfYjpbYL5cYblaCzZYrbeIBOttgo0EXm12iDd7LCVYkh3OhCyZ5VL0NCuELFKMp3MsojVmixXCRSREe3Xcp4WCwfdpju0+Q1aYM6ERQpKmRnQyJXZps9E7JBKEoBMd2mVfa92UDIf3vwzefC0RS0nUrv80xGgSYvD8ohoStAeynuZNedcNORNWDt9GFh/+WGR5oSUuolgbQQ1VR7GpI/WM4u3pO3XlOuUgsRLmRjREoyE1ogMRi+jcTJsO1HGXZOrI8vNDeYFThhziWeLQjSX9g9HI7CIJV+uGjO/t+byr4cN3g9efjXQslePXP+nWk5mkdpxLNoaZpLnRDNvwOiph5ZidxePUIT9q6EuldovjHOo4SdleT9Vx3UhzQdjWMJMMfImcL9q+dAZglu0ST0ezTVhLl3/PLL4EVjPkKWE3+SSxHjHB9dG7qeFb0fDVx4MOMmdwOJ7iaTjWY8is2N3NLj0NPdea05Lp+8VJ4ZWzzR3maaVzOJ0tVZ+5gDZ1Y7O045Kxl6SZhEYSP+x62/Bq4p3HG5cLPrrPhNFZri/9f2hKb7TskD2RUUj8OLSGlXZWlJrBbRgFD8Y/3FsCYIy6MSkAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "List of Features in a menu decorated by icons",
          "title": "List of Features in a menu decorated by icons",
          "src": "/static/53dd8f0d8b4b6cb7da69b4a384387652/fcda8/powerful-features-icon-example.png",
          "srcSet": ["/static/53dd8f0d8b4b6cb7da69b4a384387652/12f09/powerful-features-icon-example.png 148w", "/static/53dd8f0d8b4b6cb7da69b4a384387652/e4a3f/powerful-features-icon-example.png 295w", "/static/53dd8f0d8b4b6cb7da69b4a384387652/fcda8/powerful-features-icon-example.png 590w", "/static/53dd8f0d8b4b6cb7da69b4a384387652/7131f/powerful-features-icon-example.png 710w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <div className="source">Source: <a href="https://medium.com/refactoring-ui/7-practical-tips-for-cheating-at-design-40c736799886">Refactoring UI</a></div>
    <p>{`In this instance, we are not using these image icons to convey any meaning. This is just to `}<em parentName="p">{`decorate`}</em>{` every list item in the “Powerful Features” list. It is adding some design pizazz, but not really adding any syntactical value that a visually impaired user would be missing out on.`}</p>
    <p>{`From an empathetic standpoint, when you put non-empty alternative text on a decorative image, what does it do for a visually impaired user? If I were in their shoes, and I wanted to learn about what the powerful features were, I think I would be annoyed hearing images read out that did not communicate any value to me. This is usually the question I ask myself when I think about whether something is decorative or not.`}</p>
    <h2>{`What is the context of this image?`}</h2>
    <p>{`Before thinking about the descriptor, sometimes we need to ask the question first: form or function? Sometimes the easiest way to think of this is “Is this a link or a button, or is this content?” Granted, this doesn’t cover all your bases, but asking yourself whether it `}<em parentName="p">{`does something`}</em>{` like go to another page or `}<em parentName="p">{`describes something`}</em>{` that adds context to our content is usually a good first start.`}</p>
    <h3>{`Images that do things`}</h3>
    <p>{`First, let’s cover an example that `}<strong parentName="p">{`does something`}</strong>{`. Let’s take the following code here. It’s a Facebook icon that directs to the organization’s Facebook page. Currently, it does not have any alt text.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://www.facebook.com/DrupalGovCon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`facebook.svg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Sometimes it’s tempting to add alt text here that would describe the image. Like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://www.facebook.com/DrupalGovCon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`facebook.svg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`facebook icon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`The problem with this is that describing it doesn’t really help add context to what this image does. Someone using a screen reader may be able to take a logical guess about where the link is going based on all the other context of the entire site. However, I don’t want my users to guess, I want them to know! If one clicks on this image, it will go to the DrupalGovCon Facebook page. Let’s make it super clear to the end user, regardless of visual ability where this link is going:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://www.facebook.com/DrupalGovCon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`facebook.svg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Go to the DrupalGovCon Facebook Page`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3>{`Images that Describe Things`}</h3>
    <p>{`Now let’s go over an example that `}<strong parentName="p">{`describes something`}</strong>{`. I really love following food bloggers (although I usually hate how long their pages take to load, haha), and seeing what recipes they have. Admittedly, this is not a food blogger I follow, but I googled “best brownie recipe” and this was the first recipe I found. And holy guacamole, do these brownies look amazing based off the pictures!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "150%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGayj2LfMiM7fEKsj4h/8QAHRAAAgICAwEAAAAAAAAAAAAAAQIAEQMSBCEiMf/aAAgBAQABBQJWBHmL8BIG0x9qkq4i+BU2qcanwf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAAICAwEAAAAAAAAAAAAAAAARARACITFB/9oACAEBAAY/AuVqmMVQeiMZR//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQXGB/9oACAEBAAE/Idq19HPFzmiJ3pMwCzw0+5jZQdKt0LrnwVveBYvJmUL6ZAzD/9oADAMBAAIAAwAAABALwrH/xAAYEQEBAQEBAAAAAAAAAAAAAAABADERIf/aAAgBAwEBPxAydhOS+3//xAAXEQEBAQEAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EKjOo//EAB4QAQEAAwABBQAAAAAAAAAAAAERACExcUFRYYHR/9oACAEBAAE/EADc1SKzOElFCN+MGL0befGXTFqJwecUaKfd/MZUKuqnrlOPQ60+cXmLooAwhqiW50y6clwA7C7XfrEQJF0tTVz/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Gooey, fudgy chocolate brownies with melted chocolate dripping throughout",
          "title": "Gooey, fudgy chocolate brownies with melted chocolate dripping throughout",
          "src": "/static/8116aa33c5f1db6bcca6a67f62897427/1c72d/Best-Fudgiest-Brownies-IMAGE-1001.jpg",
          "srcSet": ["/static/8116aa33c5f1db6bcca6a67f62897427/a80bd/Best-Fudgiest-Brownies-IMAGE-1001.jpg 148w", "/static/8116aa33c5f1db6bcca6a67f62897427/1c91a/Best-Fudgiest-Brownies-IMAGE-1001.jpg 295w", "/static/8116aa33c5f1db6bcca6a67f62897427/1c72d/Best-Fudgiest-Brownies-IMAGE-1001.jpg 590w", "/static/8116aa33c5f1db6bcca6a67f62897427/a8a14/Best-Fudgiest-Brownies-IMAGE-1001.jpg 885w", "/static/8116aa33c5f1db6bcca6a67f62897427/fbd2c/Best-Fudgiest-Brownies-IMAGE-1001.jpg 1180w", "/static/8116aa33c5f1db6bcca6a67f62897427/b17f8/Best-Fudgiest-Brownies-IMAGE-1001.jpg 1600w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <div className="source">Source: <a href="https://cafedelites.com/worlds-best-fudgiest-brownies/">Cafe Delights</a></div>
    <p>{`How would you create this image’s alt text? This is a really fun time to get creative! Because this is a food blogger, you’d probably want to describe it in a way that sounds appetizing and makes you think “I don’t care if I am cutting my sugar intake, I must make those brownies now!”`}</p>
    <p>{`Here’s what I did:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Best-Fudgiest-Brownies-IMAGE-1001.jpg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`alt`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Gooey, fudgy chocolate brownies with melted chocolate dripping throughout`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <h2>{`What is your content strategy?`}</h2>
    <p>{`Are we writing alternative text for images that are embedded in our copywriting? Is this an image that the designer specifically wanted to communicate our brand? We need to think about who is involved in these decisions because that is who we will want to be involved in writing our alternative text.`}</p>
    <p>{`Similar to food blogging, let’s say that you’re working with a book publisher who is working to promote a new cookbook. Let’s say that the marketing department had spent a lot of time researching their target audience using focus groups, survey’s, etc. Then they put a lot of effort into their marketing plan and decided that the visuals were really important to their marketing. They partnered with a photographer and got new images of delicious recipes. Wouldn’t you want the `}<em parentName="p">{`purpose`}</em>{` of those photographs to be reflected with assistive technology? We probably want to describe the recipe the same way a TV Cooking Show host might: “spicy Italian sausage and peppers with plenty of flat leaf parsley and thyme.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`Wouldn’t you want the `}<em parentName="p">{`purpose`}</em>{` of those photographs to be reflected with assistive technology?`}</p>
    </blockquote>
    <p>{`That’s why strategy is more important than the technical aspect. This means that you `}<em parentName="p">{`should`}</em>{` be partnering with your Marketing Strategy team about writing alternative text. This should also be why Design should be involved in branding images and why they put them there. If those teams don’t exist, at the very least we thought about the purpose of the image being there. My favorite example is the example to the right from `}<a parentName="p" {...{
        "href": "https://webaim.org/techniques/alttext/#context"
      }}>{`WebAim’s documentation on Alt Text`}</a>{`. There is an image of George Washington in a battle, but if we were on a site about history or if we were on a site about art, this same image would have very different alternative text.`}</p>
    <h2>{`Don’t overthink it`}</h2>
    <p>{`If you are having difficulty with this, try to take these three strategies with you when writing your alternative text. It decreases your chances of overthinking it (which frankly, I am the queen of doing). The strategy behind writing your alternative text is strongly correlated to thinking through your strategy. And remember, it’s a work in progress. Your alternative text can adapt and improve over time :).`}</p>
    <p>{`I hope you enjoyed learning about my approach to alternative text! Feel free to reach out to me on `}<a parentName="p" {...{
        "href": "https://twitter.com/LittleKope/"
      }}>{`Twitter`}</a>{` if you have questions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      