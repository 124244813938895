import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
import CallToAction from '../../components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Content Warning: This blog post contains gifs.`}</em></p>

    <p>{`Hey friends! Today we’ll be creating custom keyboard accessible radio buttons! This blog post is a follow-up post from `}<a parentName="p" {...{
        "href": "/blog/create-custom-keyboard-accesible-checkboxes"
      }}>{`my accessible checkboxes post`}</a>{`.`}</p>
    <p>{`We’ll go over:`}</p>
    <ol>
      <li parentName="ol">{`The markup`}</li>
      <li parentName="ol">{`Creating a pseudo-element on the label in CSS`}</li>
      <li parentName="ol">{`Add “selected” styling in CSS`}</li>
      <li parentName="ol">{`Add focus styling`}</li>
    </ol>
    <h2>{`Starting out`}</h2>
    <p>{`I decided to create a simple group of radio buttons asking what your favorite animal is`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`fieldset`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`legend`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`What is your favorite Wild Animal?`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`legend`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radio-wrapper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radio`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`animal`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`elephant`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`elephant`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Elephant`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radio-wrapper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radio`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`animal`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`monkey`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`monkey`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Monkey`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radio-wrapper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radio`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`animal`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`cheetah`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`cheetah`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Cheetah`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radio-wrapper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`input`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radio`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`animal`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`giraffe`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`label`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`giraffe`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Giraffe`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`label`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`fieldset`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fieldset`}</code>{` groups all the radio buttons together logically. The radios inputs are all options to the question in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`legend`}</code>{`. Also, remember to associate those form labels with the radio buttons! If you don’t know what that means, I suggest taking a look at my `}<a parentName="p" {...{
        "href": "/blog/introduction-accessible-labeling"
      }}>{`introduction to accessible labeling`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.513513513513516%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA4klEQVQoz52RyYqEQBBE/f/v0oMgKric3HDDFcUN9xiimu45zMXphKgqyfRFVaZU1zVs20ZRFKiqCnmeI0kSZFmGMAzFdxzHiKLoI+anafqj67ogDcMgijzPE4W+7yNNU9CIO03KshRn5gln7jxPHMchxDM5+75D4rJtG3Rdh6IosCxLuP03lmV5Add1FXRN06CqKlzXFY6M+74fiTHP8y9wHEc4jgPDMGCaprjx10AuBBIkyzKCIMA38Xly3/fiyU3TiOZ3XSd6SJOnYn3bti8g+0Vx5Lw+9/fk3lN8Itbz/x9Mm2c9Su9lSwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A Fieldset with the question 'What is your favorite Wild Animal?' with four options: Elephant, Monkey, Cheetah, Giraffe.",
          "title": "A Fieldset with the question 'What is your favorite Wild Animal?' with four options: Elephant, Monkey, Cheetah, Giraffe.",
          "src": "/static/8c77a18ecda61aaf4162dc1894b51d61/fcda8/beginning-html.png",
          "srcSet": ["/static/8c77a18ecda61aaf4162dc1894b51d61/12f09/beginning-html.png 148w", "/static/8c77a18ecda61aaf4162dc1894b51d61/e4a3f/beginning-html.png 295w", "/static/8c77a18ecda61aaf4162dc1894b51d61/fcda8/beginning-html.png 590w", "/static/8c77a18ecda61aaf4162dc1894b51d61/d67ca/beginning-html.png 714w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I’m going to add some straightforward SCSS to clean it up a bit.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`@import`}</span>{` `}<span parentName="code" {...{
            "className": "token url"
          }}>{`url`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'https://fonts.googleapis.com/css?family=Roboto&display=swap'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`* `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-family`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Roboto'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` sans-serif`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`fieldset `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`I didn’t do anything much here; I added a font and took away the border from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fieldset`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.08108108108109%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA/UlEQVQoz61S2aqDUAzs/3+VD4IbiBsqIio+qOCu4ILo3JsDHrCFYksDY1zinMwkD/xH0zTI8xxd16Hve5YJdV2z57IsMQwDy23b8ndVVWGaJqLAcRwsP+gSRRFEUYTneQiCAJqmwXVd6LoO0zShqips24aiKDAMA5ZlQZZlVkfkF8LzZl1XfjphWRbcjZODE+77jqIoIEkS64JOTpKEFdA3qnmHlw4J5AdJj+MYaZpi2zZeeIfw0iGBTHYch3lIPs3zfCG8Gy+Evu8z2eTnsz8fEY7jiCzLmFySfg7lK8JzKLQmgiAgDMOv5PI9pJ9oCLSkBFqh5+l91OEv4w8JQVSvxSlHQwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The fieldset with a sans serif font and no outline on the fieldset",
          "title": "The fieldset with a sans serif font and no outline on the fieldset",
          "src": "/static/93c651ef4f162105c4d0af3442b3fc03/fcda8/basic-css.png",
          "srcSet": ["/static/93c651ef4f162105c4d0af3442b3fc03/12f09/basic-css.png 148w", "/static/93c651ef4f162105c4d0af3442b3fc03/e4a3f/basic-css.png 295w", "/static/93c651ef4f162105c4d0af3442b3fc03/fcda8/basic-css.png 590w", "/static/93c651ef4f162105c4d0af3442b3fc03/1bba8/basic-css.png 642w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now let’s get to the fun part! Styling these radio buttons!`}</p>
    <h2>{`Creating a pseudo-element on the label`}</h2>
    <p>{`First thing I am going to do is add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::before`}</code>{` pseudo-element on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`label`}</code>{` element. I’m going to start with something basic first.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$muted-red`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #db3846`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='radio'] `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`+ label `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* This will be adjusted */`}</span>{`

    `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`::before `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -22px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* This will be adjusted */`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$muted-red`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The radio buttons won’t look like anything much right now. We only want to see the radio buttons to ensure we are replicating the HTML functionality.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "582px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "45.27027027027027%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABQklEQVQoz5WRzU7CUBCFeUddGg0u3Fi3+gAuXRk3JG58Eha00DYg/RUopElDtWmBAnFXSnu8M7ERFsZ6ksnMzb3zZe6ZRlEUmM1mmM/nWC6XHKvVinMURUiShIPq9XoN3/f5Po5jLBYLhGGINE1BKssSjf1+D8Mw0O31YNs2PM+DZVkwTZObdV1Hv9/n7LouFEXhd6qqYjAYcN5utz/AUgBJn0/PCO4fYIzHsAVsNBrBcRzUFcFIjWKXIxNFeHkD++QcimlA6XSgaRpPRJbQY8pVHJ6prmA8YZHnyMUhku7gnl1BHr7CEhbQ18hbEjVWDb/FEZAm/JBuMWleY+hNoAtfZFlmjw5hdcTAHX25KcE9vYDmOlC7XbTbbWw2m6PJ6gHFUmgtaesF748tTIIAb8K76XSKLMv+NR0Dvzv+3F5d6BeW0aM+ZTekzgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Radio buttons with a red box between the label and the button.",
          "title": "Radio buttons with a red box between the label and the button.",
          "src": "/static/616046c6d96462e247493b7eb9fbee8c/7c1cd/beginning-radio-styling.png",
          "srcSet": ["/static/616046c6d96462e247493b7eb9fbee8c/12f09/beginning-radio-styling.png 148w", "/static/616046c6d96462e247493b7eb9fbee8c/e4a3f/beginning-radio-styling.png 295w", "/static/616046c6d96462e247493b7eb9fbee8c/7c1cd/beginning-radio-styling.png 582w"],
          "sizes": "(max-width: 582px) 100vw, 582px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I’m going to add a teensy amount of margin on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.radio-wrapper`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$muted-red`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #db3846`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token selector"
            }}>{`.radio-wrapper `}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0.5rem 0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='radio'] `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`+ label `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* This will be adjusted */`}</span>{`

    `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`::before `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -24px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* This will be adjusted */`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 18px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 18px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$muted-red`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "554px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.48648648648649%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABeElEQVQoz5WSS0/CQBSF+btujTvixqAbo//AxIUhLhCj7giBICGBQJuW0tIWNtCAC6KGV2kp7fHO6DTig5SbTGfuzM3XM+dOKgxDrFYrbDYb/IwoivgQaxHr9XqrjuViL8UKn6tV3OfzaNTrkCQJtVoNlUoF7XYbhUIBVTovlUqo0zlbsz1ZllEsFlEul3k+nU4/gewTBAEWvgeX1Pq+z//meR4vYOpnsxmWyyUWiwVc1+Xr+XzOczGLW6QiumpAydvVDYYHR2g1GlBJmUZDURRMJhMkCWEJAUMw997vnjA+vYQzGEBRVViWBcMwYiDzWnj634iBXGE2h1E6A6nZhNHtQu900O/3/2zKroiB77kHjE/OYRGsRyDbtqHr+hYwCfSXQo2uyxRqmsY9ZA3ZWyH3MPeIl8wF99Du9WCaJvfweweTAcMvhbd5jI7PYBNoMBxCJXUCxhqSNOJn83qdhXOYhqkbaLZakOmBO46zl3/xw8aO4qQgER+jP48MToSMTwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Radio buttons with a red box between the label and the button, with extra space below each field.",
          "title": "Radio buttons with a red box between the label and the button, with extra space below each field.",
          "src": "/static/cf1002208c7bb12a69b48b4c09518c19/04abd/increase-margin.png",
          "srcSet": ["/static/cf1002208c7bb12a69b48b4c09518c19/12f09/increase-margin.png 148w", "/static/cf1002208c7bb12a69b48b4c09518c19/e4a3f/increase-margin.png 295w", "/static/cf1002208c7bb12a69b48b4c09518c19/04abd/increase-margin.png 554w"],
          "sizes": "(max-width: 554px) 100vw, 554px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now let’s remove that background color and round out the edges.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='radio'] `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`+ label `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* This will be adjusted */`}</span>{`

    `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`::before `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -24px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* This will be adjusted */`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`border-radius`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 50%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`border`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 1px solid #6f686a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 18px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 18px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` transparent`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`As a note, I am going to leave the standard radio buttons for debugging purposes.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "542px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.83783783783784%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABY0lEQVQ4y5VSyY6CUBD0/z/Fq8S4JC7ReEDRgxsZl+GiFwVEFkHBGqonjziXCXRSeUu6q6uX2uv1QhAEyLIMvKdpivf7Ddrj8ZA3kSRJ4RPHsfzxrhBFkcTU9vs96vU6+v0+RqMRptMpBoOBvBeLBTRNw2QyQaPRgGEYGA6HaLVa4tvpdDAej8VntVr9EjIrs/n3e6Hs+XzKn1JJRQRV8k1QEcE/VqhMCAM/EJLD4YDZbIbNZiMwTROu66KsUVCNSgLflyzn81nK1HUd6/Uay+UStm2LMxMz4D+IwjRLRboQnk6i8nK5wLIs3PM2qMxlTRQykH3Zfe2KYfR6Pczn84KwLKn0MM7JOBTP86REkodhCMdxiuGUJmTA7XZDFEb4Ph5lLdrtNrrdrpycYiVCOnLCXk5KVQrX6/XP9Er3kFvu5GUm+Z6Z2y2azaYsKk814Uo9pCP7yEnzVPhUVomwzLJWsR9HE+dvb0SigAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Radio buttons with a large circle between the labels.",
          "title": "Radio buttons with a large circle between the labels.",
          "src": "/static/bcda24731357b957974608bba50c96a0/c0388/circle-radio-start.png",
          "srcSet": ["/static/bcda24731357b957974608bba50c96a0/12f09/circle-radio-start.png 148w", "/static/bcda24731357b957974608bba50c96a0/e4a3f/circle-radio-start.png 295w", "/static/bcda24731357b957974608bba50c96a0/c0388/circle-radio-start.png 542w"],
          "sizes": "(max-width: 542px) 100vw, 542px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Add `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`:checked`}</code>{` styling in CSS`}</h2>
    <p>{`If you’ve read my post on `}<a parentName="p" {...{
        "href": "/blog/create-custom-keyboard-accesible-checkboxes"
      }}>{`keyboard accessible checkboxes`}</a>{` you know about the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:checked`}</code>{` pseudo-class. First, we need to put add an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::after`}</code>{` pseudo-element on the label.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='radio'] `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`+ label `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* This will be adjusted */`}</span>{`

    `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`::before `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -24px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* This will be adjusted */`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid #6f686a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 18px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 18px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` transparent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token selector"
            }}><span parentName="span" {...{
                "className": "token parent important"
              }}>{`&`}</span>{`::after `}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`content`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`''`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`position`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` absolute`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` inline-block`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`left`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` -20px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`top`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 4px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`border-radius`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 50%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 12px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`height`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 12px`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$muted-red`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now, this is what that looks like:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "530px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.83783783783784%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABl0lEQVQ4y5VSTU/CQBDt/796UQ/GkxwsByAYoOWbQASiMTEhhgRbpFCFVmhpi6XwnNmkjTEGYZKXaXdnZ9++N9Jut4PnefgreI/Bsd/vBeL1KIqSdY7NZoMwDCHNZjOkUikUCwV0u130ej20222oqop+v49qtYpKpYJSqYRWqyVqcrkcms2mQLlcRq1WQ7FYhG3bkLbbLYIgwHITwKP8RTc5jiPALHjPsiysVisB13WTb27A/3HmkJhyGG3x2X2A9arjeTDAbDqFpmnQdR2LxQKnhMQs1sTCuLnF9PEJDXquUlLEsxVFwXA4THSLdTwEiQtDevbnxIC7XMIi+izuer1OBI/z0Qwd38fb+RW0VgdyJgOVmOXzeWSzWaHVb5cPNuSiHcE23+HT+PAI8SXMnFmyaaewlCI6sKIm47NL6MQwT+PBI1QgpNNpGIZxWkPBkNgsyc3A88VBYRSx80mKn4N9NEPWcHJxjXHnHnekX6NehyzLGI1GicNHmxK7bA9e4M7n+CCYpok55XhYjzVENPyv4JSR4fgGSsPgsd837WAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Radio buttons with an outlined red circle between the labels.",
          "title": "Radio buttons with an outlined red circle between the labels.",
          "src": "/static/e496ee5597c494b8fc5b6439ac36d1c5/b6a9b/after-element.png",
          "srcSet": ["/static/e496ee5597c494b8fc5b6439ac36d1c5/12f09/after-element.png 148w", "/static/e496ee5597c494b8fc5b6439ac36d1c5/e4a3f/after-element.png 295w", "/static/e496ee5597c494b8fc5b6439ac36d1c5/b6a9b/after-element.png 530w"],
          "sizes": "(max-width: 530px) 100vw, 530px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now we have the styling in place. Let’s only add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`background`}</code>{` of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::after`}</code>{` pseudo-element when the radio input is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:checked`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='radio'] `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`+ label `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`::after `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -20px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 4px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 12px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 12px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token selector"
            }}><span parentName="span" {...{
                "className": "token parent important"
              }}>{`&`}</span>{`:checked `}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token selector"
            }}>{`+ label::after `}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$muted-red`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`So now if I select a radio button, it’ll have a background color!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "538px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.48648648648649%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABe0lEQVQoz5VTaWvCQBTM//8j/dCDfgiCKPGOYINVoqAmxgMhrQc15jAxxzTv2YiFSuPAy272mJ2d91ZAiiAIsNvt4Ps+PM/D6XTiMWpt2+b+8XiE4zgIwxCu63LQOLXZHO0XiHCi6Xh8eoZULqPRaKDX66FUKqHVaqHdbqNYLEKWZYiiyHOFQgG1Wg3VahWSJKFSqfCa7XYLIU6Ikj8I8DeiKEIcx0iS5Fef1GZj1BIEIpMN4KH6hcPLK97fFNSbTXQ6HVarKApfm4/92XQLNM+EygIQFQdWSrA2P6BPpzAMA4vFAsvlkr3JNvwXwvnQCKFvw0t/NE2DqqoYj8cYDofYbDa51GUQzh7FsB0XtmVhtVpBHQwwGo3Q7/cxTdVeq8tJGJ1LIU39+nONyWSC2WzGQYSUhLwqL4ROajyFaZqsjK5NCel2uxei3ISk4JgW9ME6sGd07fl8zom5zuAdHkaw9nt+Jbquc9HW63UubLLibg8z0FO7VV958Q3XSJYYzBbKYAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A selected radio button with an outlined red circle, indicating it is selected.",
          "title": "A selected radio button with an outlined red circle, indicating it is selected.",
          "src": "/static/c8cfb4fa8b378b87db92b719051bc43f/9516f/selected.png",
          "srcSet": ["/static/c8cfb4fa8b378b87db92b719051bc43f/12f09/selected.png 148w", "/static/c8cfb4fa8b378b87db92b719051bc43f/e4a3f/selected.png 295w", "/static/c8cfb4fa8b378b87db92b719051bc43f/9516f/selected.png 538w"],
          "sizes": "(max-width: 538px) 100vw, 538px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`If you notice, though, there is no focus styling. Let’s focus on that next (see what I did there)`}</p>
    <h2>{`Add focus styling`}</h2>
    <p>{`If I were to hide the radio button, you would have no idea if I focused on it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "526px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.86486486486486%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABeUlEQVQ4y5VT2YrCQBDM//+NKCoqmiCCtxhEFB9CfJFVo7k0mqs21W4WVlaMDZ3p6empVB+jIJPj8QjHcXC73XC9XhHHMcIwlD39tC+XCzzPE5s+3/flnOv9fsfpdJK7SpIkqFarqNfrUDUV/X4fw+EQ5XIZs9kM7XZbtNPpSMx8PkelUkGv10Or1RI/z5vNpoAqQEqSSH5Y/SdRFAlr/pxKm0o/9/lKUaIYML4C7C0PXpZKt9vFeDzGYDDAdDrFarX6DX4naZpCCeMU5iHMAF14roPtdovFYiFA6/UapmkKm/zCO5WUWVTfc+HYNnRdF5DNZgPDMKTwOVgRURjIS65Lhh5Go5E0YzKZSOEPh8MfdoUA2QyOBHW328GyLLEJRvtjwCAIYGfp2ucz9GwsVFWFpmloNBpYLpePKcgaUxiQRefgckjPGSgHdL/fC8tP6vcAzD4EIkO+ANatVquhVCrJ2ORgH6VMYR3Z7WdGr+yXgK8CnxkVTfsb8RjmLYcrKwwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A focused radio button with an outlined circle.",
          "title": "A focused radio button with an outlined circle.",
          "src": "/static/273df5db2f408f077909354ebc454a39/2d7ab/no-focus.png",
          "srcSet": ["/static/273df5db2f408f077909354ebc454a39/12f09/no-focus.png 148w", "/static/273df5db2f408f077909354ebc454a39/e4a3f/no-focus.png 295w", "/static/273df5db2f408f077909354ebc454a39/2d7ab/no-focus.png 526w"],
          "sizes": "(max-width: 526px) 100vw, 526px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='radio'] `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`:focus `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`+ label::before `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0px 8px `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$muted-red`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`I decided to add a similar muted red for the focus styling.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "556px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.83783783783784%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABeklEQVQ4y5VTi4qCUBD1/z9ngwoKoyyiaCFYoZdspawSWmo+y8fZmbvkLhGsDozXO84995yZUQJZmqa43+8oikL4w/id42x5nlff+J39kcOWJAnKsoTEm36/j7dWC/JgAFVVMZ1OIcsyFosFxuMxBhTn/XA4FLF2u43JZCLiHOv1euLMDyA98jxDHATwzxcUtI/jWNzItzMT3/cRRRECyuGVPQxDXK9Xkcvr7XYTTKWiBEw7hXO04B11rD9UrDcb7HY7bLdb6LqOuiYY5gT4aSb40gyc93toqxUUkrlcLjGfz6FpWpX8nwuGLDkjyaHrwT2dcDgcYJkmDMOAZVnIsqwCrGMSJ3KXuT6e5+Gdij6bzYRzwW3bbg7IhQ+5KQToOI4AcV1XMOSC/5VcC5A7GtBB93IRY8PjoCgKut0u9lTXxoDMMOIxoPFg2XwBs+PxaCL3l2GcwKOmnEkuD/JoNEKn0xFdfu5wLcDqd8ryl7PVqCmvAJ4ZNZH8DYEF51Gd3AXRAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A focused radio button with an outlined red circle.",
          "title": "A focused radio button with an outlined red circle.",
          "src": "/static/9e338a63f86dc51bd1fc94510bf50db1/96638/focus-styling.png",
          "srcSet": ["/static/9e338a63f86dc51bd1fc94510bf50db1/12f09/focus-styling.png 148w", "/static/9e338a63f86dc51bd1fc94510bf50db1/e4a3f/focus-styling.png 295w", "/static/9e338a63f86dc51bd1fc94510bf50db1/96638/focus-styling.png 556w"],
          "sizes": "(max-width: 556px) 100vw, 556px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`To finish up, I will:`}</p>
    <ul>
      <li parentName="ul">{`remove the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`opacity`}</code>{` from the radio button itself (the input)`}</li>
      <li parentName="ul">{`remove the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`margin-left`}</code>{` from the label!`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`input[type='radio'] `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`+ label `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And Voilà!`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/RgzwLbAOB4W4aSUkog/giphy.gif",
        "alt": "Gif going through custom radio buttons."
      }}></img></p>
    <h2>{`Conclusion`}</h2>
    <p>{`When we make custom radio buttons, we have to make sure we account for the following:`}</p>
    <ol>
      <li parentName="ol">{`Creating proper HTML structure with associated form labels!`}</li>
      <li parentName="ol">{`Using pseudo-elements to create the custom-styled element`}</li>
      <li parentName="ol">{`Accounting for the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`:checked`}</code>{` pseudo-class`}</li>
      <li parentName="ol">{`Ensuring you can focus on the new radio button`}</li>
      <li parentName="ol">{`Use `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`opacity: 0`}</code>{` to hide the radio button`}</li>
    </ol>
    <p>{`If you want to play around with it, here is the finished CodePen!`}</p>
    <iframe height="445" style={{
      "width": "100%"
    }} scrolling="no" title="Starting HTML - Keyboard Accessible Custom Radio Buttons" src="//codepen.io/littlekope0903/embed/jjgRPL/?height=445&theme-id=0&default-tab=css,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/littlekope0903/pen/jjgRPL/">
    Starting HTML - Keyboard Accessible Custom Radio Buttons
  </a>{' '}
  by Lindsey Kopacz (
  <a href="https://codepen.io/littlekope0903">@littlekope0903</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p><strong parentName="p">{`EDIT:`}</strong></p>
    <p>{`I wanted to give a shout out to some feedback/help from some cool people from Twitter for helping me polish a few things. In particular, I wanted to shout out `}<a parentName="p" {...{
        "href": "https://twitter.com/elizschafer"
      }}>{`Elizabeth Schafer`}</a>{` for forking this and making some tweaks for windows high contrast mode! Super cool!`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Keyboard Accessible Custom Radio Buttons" src="//codepen.io/eschafer/embed/wVmwoy/?height=450&theme-id=dark&default-tab=css,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen{' '}
  <a href="https://codepen.io/eschafer/pen/wVmwoy/">
    Keyboard Accessible Custom Radio Buttons
  </a>{' '}
  by Elizabeth Schafer (<a href="https://codepen.io/eschafer">@eschafer</a>) on{' '}
  <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`If you liked this blog post and are interested in learning more about accessibility, take my `}<a parentName="p" {...{
        "href": "https://a11y-with-lindsey.ck.page/b4e437f629"
      }}>{`10 days of a11y`}</a>{` free email course.`}</p>
    <p>{`Cheers! Have a great week!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      