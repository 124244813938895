import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`EDIT: I resigned from the podcast in late August 2019. The accessibility lessons I learned are still relevant if you’d like to start a podcast, but in full transparency, this post is technically out of date.`}</em></p>
    <p>{`If you haven’t noticed, I haven’t been around! It’s been a month since I posted, and there’s been a good reason for that. Kelly Vaughn, Ali Spittel, Emma Bostian, and I started the Ladybug Podcast. This podcast came to fruition with much labor. As a result, I’ve been figuring out how to balance this blog with that side project! Don’t worry, though, this isn’t going away. My blog is my passion project!`}</p>
    <p>{`Now that you know where I’ve been, I’ve learned a lot about podcasting and how it intersects with accessibility. I knew some of these things at a high level, but in this post, we’re going to get a little bit more in-depth.`}</p>
    <h2>{`Transcripts are a MUST`}</h2>
    <p>{`When we started planning this podcast, it was no doubt we wanted to have transcripts. Transcripts can be time and financially expensive, and many people see them as a “nice to have.” However, I’m here to tell you that they are a must-have.`}</p>
    <p>{`Transcripts have a ton of benefits:`}</p>
    <ol>
      <li parentName="ol">{`Deaf and hard of hearing folks can access your excellent content.`}</li>
      <li parentName="ol">{`Some people prefer reading than listening. I cannot tell you how many abled folks have told me they appreciated having transcripts.`}</li>
      <li parentName="ol">{`It helps those whose first language may not be English.`}</li>
      <li parentName="ol">{`If people are reading your transcripts, this means they are spending a ton of time on your site. Session duration improves SEO.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Being a content creator means that you try to make your content available to as many people as possible. You don’t want to alienate a potential audience member because you decided to ignore their needs.`}</p>
    </blockquote>
    <p>{`We went through a few attempts to get transcripts. I wanted to share the options we tried and the pros and cons of each option.`}</p>
    <h3>{`Having someone do it for you`}</h3>
    <p>{`Hiring someone was the first thing we tried because it was the only option we knew. We didn’t have time to go through our entire audio and write it out. So we decided to hire someone who did that for us. We used Fiverr, which I had never tried before. Hiring someone has pros and cons, however.`}</p>
    <p>{`Pros:`}</p>
    <ul>
      <li parentName="ul">{`Paying a real person and supporting someone else.`}</li>
      <li parentName="ul">{`Catches errors in real-time`}</li>
    </ul>
    <p>{`Cons:`}</p>
    <ul>
      <li parentName="ul">{`Expensive`}</li>
      <li parentName="ul">{`Because they are a human, it takes a few days to get them back, which isn’t ideal for a quick turn around.`}</li>
      <li parentName="ul">{`Language differences.`}</li>
    </ul>
    <h3>{`Using Artificial Intelligence`}</h3>
    <p>{`The other option is to use AI software to parse through your audio and provide a transcript for you. We tried `}<a parentName="p" {...{
        "href": "https://app.trint.com"
      }}>{`Trint`}</a>{` and `}<a parentName="p" {...{
        "href": "https://otter.ai"
      }}>{`Otter AI`}</a>{`, which are both great tools. AI also has some pros and cons.`}</p>
    <p>{`Pros:`}</p>
    <ul>
      <li parentName="ul">{`Get your transcript back in minutes or hours. Otter AI took about 10 minutes.`}</li>
      <li parentName="ul">{`Cheaper`}</li>
      <li parentName="ul">{`Gets better as they learn your speakers`}</li>
    </ul>
    <p>{`Cons:`}</p>
    <ul>
      <li parentName="ul">{`Especially at first, you have to edit it. We didn’t want to have random words in there, so we took some time to listen to the tool as it read the transcript to us.`}</li>
      <li parentName="ul">{`I feel a little guilty when I use Machine Learning to replace a job that a person could do.`}</li>
    </ul>
    <h3>{`What we ended up doing`}</h3>
    <p>{`Because of the quick turn around we needed, we ended up going with AI. We got a deal with OtterAI and ended up using that one. Do what works best for you in the end, all options are great ones!`}</p>
    <h2>{`A11y Considerations for Custom Podcast Players`}</h2>
    <p>{`As you may or may not know, we used `}<a parentName="p" {...{
        "href": "http://gatsbyjs.org"
      }}>{`GatsbyJS`}</a>{` to build out the LadyBug Podcast site. I took responsibility for making an accessible podcast player. I learned so much about how to use React to create all the audioElement events. Shout out to `}<a parentName="p" {...{
        "href": "https://syntax.fm/"
      }}>{`Syntax.fm`}</a>{` for having a player that I could explore. The first thing I did was add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ref`}</code>{` to an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<audio>`}</code>{` element.`}</p>
    {
      /*prettier-ignore*/
    }
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Player`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` show `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`audio`}</span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ref`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`audio`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`audio `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` audio`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`show`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`audio`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`If I go into the code and add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`console.log`}</code></p>
    {
      /*prettier-ignore*/
    }
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Player`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` show `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    console`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`audio`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`audio`}</span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ref`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`audio`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`audio `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` audio`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`show`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`audio`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This gives me the ability to access all the methods of the audio. I can use them on my custom player in the form of buttons, radio buttons, and a slider.`}</p>
    <p>{`In the podcast player, I used the following methods:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/currentTime"
        }}>{`HTMLMediaElement.currentTime`}</a>{` - This tells you current time in the audio, in seconds.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/playbackRate"
        }}>{`HTMLMediaElement.playbackRate`}</a>{` - This tells you the speed at which you are playing. We can set this property when we press a button to increase the speed!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/volume"
        }}>{`HTMLMediaElement.volume`}</a>{` - This tells you the current volume level.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play"
        }}>{`HTMLMediaElement.play()`}</a>{` - This plays the audio.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/pause"
        }}>{`HTMLMediaElement.pause()`}</a>{` - This pauses the audio.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/duration"
        }}>{`HTMLMediaElement.duration`}</a>{` - This tells you the total length of the audio, in seconds.`}</li>
    </ul>
    <p>{`Once you add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ref`}</code>{` to the audio, you can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`this.audio`}</code>{` to control anything with the audio. You can append the method or property name to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`this.audio`}</code>{`. It’s neat! You can use that as well to control React state 🤯! I won’t be going too much into all the states in this blog post. I recommend you check out `}<a parentName="p" {...{
        "href": "https://github.com/ladybug-podcast/ladybugpodcast/blob/master/src/components/player.js"
      }}>{`our source code`}</a>{` as well as the `}<a parentName="p" {...{
        "href": "https://github.com/wesbos/Syntax/blob/master/components/Player.js"
      }}>{`Syntax.fm source code`}</a>{` if you want to learn more.`}</p>
    <h3>{`Button Labelling`}</h3>
    <p>{`Button labels are the most critical part of the player being accessible. Remember, we are “hacking” the audio player. We must ensure screen reader, keyboard, and mouse users can access the player. The latter is what abled folks center on, so if you’re doing this, I want to make sure you account for all experiences.`}</p>
    <p>{`I used the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`react-icons/fa`}</code>{` package to get the icons for our player. We used `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FaPlay`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FaPause`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FaUndo`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FaRedo`}</code>{` for the play, pause, rewind 15 seconds, and fast-forward 15 seconds buttons. These icons are SVG icons, but I wanted to make sure there was text in the markup. To do this, we ensured the following:`}</p>
    <ol>
      <li parentName="ol">{`We used the semantic `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<button>`}</code>{` instead of a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` to support keyboard events. Read my `}<a parentName="li" {...{
          "href": "/blog/3-simple-tips-improve-keyboard-accessibility"
        }}>{`3 Simple Tips to Improve Keyboard Accessibility`}</a>{` post for more information.`}</li>
      <li parentName="ol">{`We ensured not to use icon fonts, which was already taken care of us through the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`react-icons`}</code>{` library.`}</li>
      <li parentName="ol">{`We ensured there was `}<strong parentName="li">{`actual text`}</strong>{` inside the buttons, which SVG doesn’t do for us. We can visually hide that text using CSS, but it still must be there.`}</li>
    </ol>
    {
      /*prettier-ignore*/
    }
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__icon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`svg`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!--paths and things-->`}</span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`svg`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`sr-only`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`play`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3>{`Creating a slider`}</h3>
    <p>{`I was searching around for an accessible player that shows the slider. I found the `}<a parentName="p" {...{
        "href": "https://ableplayer.github.io/ableplayer/demos/"
      }}>{`Able Player`}</a>{` and started observing how they did things. Making this accessible was one of the hardest things I’ve done, and it’s still rather buggy. The code ended up being many divs with aria labels and lots of event handling. The side project conundrum is that done is better than perfect. Looking back, I wish I had made it a slider using `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range"
      }}>{`the range input`}</a>{`. Lessons learned - stay tuned for a future blog post when I update that slider!`}</p>
    <p>{`For now, I’ll go through my thought process of what I wanted to do with the slider. Below is the source code…`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`scrub`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ref`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`x`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`progress `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` x`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress-loaded`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress-played`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` width`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token template-string"
              }}><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`(`}</span>{`currentTime `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`/`}</span>{` duration `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`+`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`0.015`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`)`}</span>{` `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`*`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`100`}</span><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`%`}</span><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`orientation`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`horizontal`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onKeyDown`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`moveSlider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`tabIndex`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__slider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`slider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-label`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`audio timeline`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuemin`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuemax`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`duration`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuetext`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`renderValueText`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`currentTime`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuenow`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`renderValueNow`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`currentTime`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` left`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token template-string"
              }}><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`(`}</span>{`currentTime `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`/`}</span>{` duration `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`-`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`0.01`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`)`}</span>{` `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`*`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`100`}</span><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`%`}</span><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`I have 3 children divs of the player: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.player__progress-loaded`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.player__progress-played`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.player__slider`}</code>{`. Let’s look at what these correspond to:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "36.486486486486484%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABLElEQVQoz41R226CUBDkL214Apv08k/tU9MvsEZq330SNKACGhoVECOiQAhKiE7PbtKmaXrbZM7uuWR2Zo+02+3gOA5M08R6vcZqtcJsNsNoNILrupjP5/A8D5ZlcU1xPp9/hFRVFaIownK5RJZlWCwWCMMQcRxjv99js9kgCAKkaQp6+1dItBBRkiSMyWQC27ZZLRGTel3Xkec5KyjLklEUBefD4cCZ7qkhEybbLWKhJBWKtqKmOJ1OTOD7PsbjMVummhoYhoHhcIjpdMrqSUC/3weNjwkjoew1DOAL6+9z+i1oxnVdf2+5fHhEdnWD7PoWxd09nrRnXDQauGw2oaoqFEX5wNf953NZltHr9SDVwsKx2xV4QTUYwBE/22q1oGkaOp3Ov9Fut3nub5nV7BF16WvWAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Custom audio player: played portion is the color red and slider is a white circle.",
          "title": "Custom audio player: played portion is the color red and slider is a white circle.",
          "src": "/static/3eb0883f8dcab597e51001e7657e8597/fcda8/annotated-player-screenshot.png",
          "srcSet": ["/static/3eb0883f8dcab597e51001e7657e8597/12f09/annotated-player-screenshot.png 148w", "/static/3eb0883f8dcab597e51001e7657e8597/e4a3f/annotated-player-screenshot.png 295w", "/static/3eb0883f8dcab597e51001e7657e8597/fcda8/annotated-player-screenshot.png 590w", "/static/3eb0883f8dcab597e51001e7657e8597/5d72a/annotated-player-screenshot.png 834w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`.player__progress-loaded`}</code>{` corresponds to the width of the entire slider. This div has no semantic value to a screen reader user.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`.player__progress-played`}</code>{` is the visual representation of the total amount played.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`.player__slider`}</code>{` is the most important element for accessibility. It tells the screen reader user what time it currently is, and allows us to move it with our keyboard.`}</li>
    </ul>
    <p>{`Now let’s take a look at some of the events and style here:`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Player`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// skip to render part of the component`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` isPlaying`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` currentTime`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` duration`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` playbackRate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` show `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`        `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`onClick`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token keyword"
                }}>{`this`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`scrub`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span></span>{`        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ref`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`x`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`progress `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` x`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress-loaded`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress-played`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`          `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`style`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` width`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="span" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token interpolation"
                  }}><span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`\${`}</span><span parentName="span" {...{
                      "className": "token punctuation"
                    }}>{`(`}</span>{`currentTime `}<span parentName="span" {...{
                      "className": "token operator"
                    }}>{`/`}</span>{` duration `}<span parentName="span" {...{
                      "className": "token operator"
                    }}>{`+`}</span>{` `}<span parentName="span" {...{
                      "className": "token number"
                    }}>{`0.015`}</span><span parentName="span" {...{
                      "className": "token punctuation"
                    }}>{`)`}</span>{` `}<span parentName="span" {...{
                      "className": "token operator"
                    }}>{`*`}</span>{` `}<span parentName="span" {...{
                      "className": "token number"
                    }}>{`100`}</span><span parentName="span" {...{
                      "className": "token interpolation-punctuation punctuation"
                    }}>{`}`}</span></span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`%`}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span>{` `}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span></span>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`orientation`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`horizontal`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onKeyDown`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`moveSlider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`tabIndex`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__slider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`slider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-label`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`audio timeline`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuemin`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuemax`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`duration`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuetext`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`renderValueText`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`currentTime`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuenow`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`renderValueNow`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`currentTime`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` left`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token template-string"
              }}><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`(`}</span>{`currentTime `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`/`}</span>{` duration `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`-`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`0.01`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`)`}</span>{` `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`*`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`100`}</span><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`%`}</span><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`For the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onClick`}</code>{` event, we want to ensure that we change the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`currentTime`}</code>{` in the audio to be wherever we clicked. If you can, look at `}<a parentName="p" {...{
        "href": "https://github.com/ladybug-podcast/ladybugpodcast/blob/master/src/components/player.js#L125"
      }}>{`the code`}</a>{` yourself, fork it, and console log all the things to see what’s happening. If you click anywhere on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.player__progress`}</code>{` div, you’ll change the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`currentTime`}</code>{`.`}</p>
    <p>{`For the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.player__progress-played`}</code>{` div, we have the styling. The component’s width is the percentage of the way through the episode.`}</p>
    <p>{`Now for the slider itself:`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Player`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// skip to render part of the component`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` isPlaying`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` currentTime`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` duration`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` playbackRate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` show `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`scrub`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ref`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`x`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`progress `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` x`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress-loaded`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__progress-played`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` width`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token template-string"
              }}><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`(`}</span>{`currentTime `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`/`}</span>{` duration `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`+`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`0.015`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`)`}</span>{` `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`*`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`100`}</span><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`%`}</span><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token plain-text"
            }}>{`        `}</span><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span></span>{`          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`orientation`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`horizontal`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onKeyDown`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`moveSlider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`tabIndex`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`player__slider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`slider`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-label`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`audio timeline`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuemin`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuemax`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`duration`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuetext`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`renderValueText`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`currentTime`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuenow`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`renderValueNow`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`currentTime`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
          `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` left`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token template-string"
              }}><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span><span parentName="span" {...{
                  "className": "token interpolation"
                }}><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`\${`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`(`}</span>{`currentTime `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`/`}</span>{` duration `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`-`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`0.01`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`)`}</span>{` `}<span parentName="span" {...{
                    "className": "token operator"
                  }}>{`*`}</span>{` `}<span parentName="span" {...{
                    "className": "token number"
                  }}>{`100`}</span><span parentName="span" {...{
                    "className": "token interpolation-punctuation punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token string"
                }}>{`%`}</span><span parentName="span" {...{
                  "className": "token template-punctuation string"
                }}>{`\``}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "token plain-text"
          }}>{`      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`…and then a couple of `}<a parentName="p" {...{
        "href": "https://github.com/ladybug-podcast/ladybugpodcast/blob/master/src/utils/util.js"
      }}>{`helper functions`}</a>{`, one of which I adapted from Syntax’s site.`}</p>
    <p>{`Holy batman that’s a lot of ARIA attributes! Let’s dissect this for a second:`}</p>
    <ol>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`role="slider"`}</code>{` - This tells the screen reader user that un-semantic div has some meaning!`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`aria-valuetext`}</code>{` - This is the human-readable version of the value of the slider. For example, in the screenshot above - it was “5 minutes, 51 seconds”`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`aria-valuenow`}</code>{` - defines the current value for a range widget (which the slider is)`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`aria-valuemin`}</code>{` - a required attribute that defines the minimum value for a range widget`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`aria-valuemax`}</code>{` - a required attribute that defines the maximum value for a range widget`}</li>
    </ol>
    <p>{`Below is how VoiceOver on macOS reads the slider:`}</p>
    <p>{`“5 minutes, 51 seconds, audio timeline, slider”. So the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-valuetext`}</code>{`, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-label`}</code>{` and then the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`role`}</code>{`.`}</p>
    <p>{`With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`this.moveSlider`}</code>{`, if you press a Left or Right arrow and respectively rewinds or fast-forwards 5 seconds. Take a look at `}<a parentName="p" {...{
        "href": "https://github.com/ladybug-podcast/ladybugpodcast/blob/e0cfce0159d1f516812465fbeafaaf5ecd47ef5a/src/components/player.js#L137"
      }}>{`the source code`}</a>{` to see how we did that.`}</p>
    <h3>{`How I want to redo the slider`}</h3>
    <p>{`As stated before, this was a little buggy. A few things that are buggy:`}</p>
    <ol>
      <li parentName="ol">{`Browser support (I got a comment from a listener, but haven’t checked it out yet)`}</li>
      <li parentName="ol">{`I couldn’t figure out dragging the slider with your mouse.`}</li>
    </ol>
    <p>{`So, for whatever reason, I assumed (don’t assume folks, it’s dangerous) that you could not custom style a range input.`}</p>
    <p>{`I was wrong. You totally can.`}</p>
    <h2>{`Conclusions`}</h2>
    <p>{`Starting a podcast has been a very positive experience all around. I’m sure we’ll talk about the more general lessons we learned starting a podcast on the Ladybug podcast!`}</p>
    <p>{`Two key takeaways from this post:`}</p>
    <ol>
      <li parentName="ol">{`Always have transcripts. It’s worth the time and financial cost to include others. It is not an edge case.`}</li>
      <li parentName="ol">{`Hack the audio player with caution and ensure that you over-test.`}</li>
    </ol>
    <p>{`If you’re interested in the LadyBug Podcast, be sure to `}<a parentName="p" {...{
        "href": "https://link.chtbl.com/ladybugpodcast"
      }}>{`subscribe`}</a>{` to have them downloaded!`}</p>
    <p>{`Cheers! Have a great week!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      