import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Content Warning: This blog post contains gifs.`}</em></p>
    <p>{`If you didn’t know this already, I usually cross post all these on `}<a parentName="p" {...{
        "href": "https://dev.to"
      }}>{`DEV`}</a>{`. When I cross-posted about why accessibility isn’t an edge case, I got a comment wishing accessibility was more straightforward to implement. I didn’t find learning to make accessible solutions to be that challenging once I was up to date on HTML and CSS best practices, so I was genuinely curious about where they found it difficult. When `}<a parentName="p" {...{
        "href": "https://dev.to/marek/comment/882m"
      }}>{`they answered`}</a>{`, it seemed like the primary pain point was ARIA.`}</p>
    <p>{`I’ve seen a ton of confusion about ARIA and when it should and shouldn’t be used. What do these ARIA attributes even mean? Is there a list of all the attributes? I am thinking about making this into a series, maybe going over a few ARIA attributes a week and how they can be used, whether I like them, etc. Before I get into the weeds about all the ARIA attributes though, I really need to get a few things straight about what ARIA is used for, and what I don’t think you should use it for.`}</p>
    <h2>{`What the hell is ARIA?`}</h2>
    <p>{`ARIA is a bunch of attributes that you can add to your elements to give them extra context and meaning. This can be useful for a lot of things. Here is what I find helpful about ARIA:`}</p>
    <ul>
      <li parentName="ul">{`Telling you when you are loading dynamic content`}</li>
      <li parentName="ul">{`Alerting a user when something important pops up`}</li>
      <li parentName="ul">{`Telling screen readers when the state changes`}</li>
      <li parentName="ul">{`Adding some extra context if needed`}</li>
    </ul>
    <p>{`The documentation on ARIA is pretty damn intimidating. Even as an accessibility person, I find it a bit daunting. The way I learned about ARIA is by opening up a well-used application like Twitter. I then inspected the elements and searched in the HTML for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria`}</code>{` and looked at all the attributes. I then turned on my screen reader and actually listened to what was actually announced when I reached that particular element. For example, when I use VoiceOver on Mac and open up the homepage of Twitter, this is the markup of my profile picture in the upper right-hand corner:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`a`}</span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`/settings`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`user-dropdown-toggle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-haspopup`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`true`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- Children Elements -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p><strong parentName="p">{`NOTE: For readability, I have removed some of the attributes and classes from the markup`}</strong></p>
    <p>{`I spot two ARIA attributes at first glance: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`role="button"`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-haspopup="true"`}</code>{`. So what does this mean when I use my screenreader.`}</p>
    <figure>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "18.91891891891892%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9ElEQVQY0zWN207CUBBF+0sqoqJGQim9QjRE44MNUgpGv0E+wFvQWnpJiCK0hb9cnnOiDyt7z+zJHu0rLVkttnznJcXPhmqzpShKyrJSrIuK5WottORxOqV2sM9h44havc7O7h6N4xNs18N2PBy3i+bfBIzDB3w/YDS6YzK5ZxiEBKMxg6HYhRMGtwGm7Sq83gWW0xXeUxiWg96xOGsZnPev0Iy2CDpdbKuHbQsVR44nvPgmscRnidzJgpZh0tQ7ClmifNvktKnTv7xGmz0lRG85Hy8Z0WtGmmYkf0gfJynxPFVzJFQSzxM+/4kTlT+/x8zyJb8kvbPsKu9YTAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "VoiceOver text that says Profile and Settings, pop up button",
          "title": "VoiceOver text that says Profile and Settings, pop up button",
          "src": "/static/36fb08e8f2a20bc5e114a7de4c3d0fab/fcda8/profile-settings-popup-button-voiceover.png",
          "srcSet": ["/static/36fb08e8f2a20bc5e114a7de4c3d0fab/12f09/profile-settings-popup-button-voiceover.png 148w", "/static/36fb08e8f2a20bc5e114a7de4c3d0fab/e4a3f/profile-settings-popup-button-voiceover.png 295w", "/static/36fb08e8f2a20bc5e114a7de4c3d0fab/fcda8/profile-settings-popup-button-voiceover.png 590w", "/static/36fb08e8f2a20bc5e114a7de4c3d0fab/efc66/profile-settings-popup-button-voiceover.png 885w", "/static/36fb08e8f2a20bc5e114a7de4c3d0fab/c83ae/profile-settings-popup-button-voiceover.png 1180w", "/static/36fb08e8f2a20bc5e114a7de4c3d0fab/f8836/profile-settings-popup-button-voiceover.png 1214w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`First thing VoiceOver says.`}</figcaption>
    </figure>
    <figure>{`
  `}<span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "13.513513513513512%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAwElEQVQI1y2N22qDQABE/bI+FKTEJE022nW9Z5O63qokBqmxUNr++KmVPhwOMwyMpTNDaVpM3lCYhq67kpuSoqqp6jdMUdF2F+qmRZ9eyed81Gf8ICKIksVxelxsPzlYZdEyjZ/c+neG4c44TtynD/rbwNf3D5drT6ZPpJlGqpAoyQjiFOFKDp6P8OTSuzLg4dHGcoVC+QnyJUKpGOmH83OMP4+CMF7G+4OHmPnzdidwtnvWM84/q82O9bPAXm34BVc2ff6/uu/0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "VoiceOver text that says You are currently on a po pup button, to display a list of options press control option space.",
          "title": "VoiceOver text that says You are currently on a po pup button, to display a list of options press control option space.",
          "src": "/static/22c146abab05f0f1e18e2f085892b2cb/fcda8/popup-button-instructions-voiceover.png",
          "srcSet": ["/static/22c146abab05f0f1e18e2f085892b2cb/12f09/popup-button-instructions-voiceover.png 148w", "/static/22c146abab05f0f1e18e2f085892b2cb/e4a3f/popup-button-instructions-voiceover.png 295w", "/static/22c146abab05f0f1e18e2f085892b2cb/fcda8/popup-button-instructions-voiceover.png 590w", "/static/22c146abab05f0f1e18e2f085892b2cb/efc66/popup-button-instructions-voiceover.png 885w", "/static/22c146abab05f0f1e18e2f085892b2cb/c83ae/popup-button-instructions-voiceover.png 1180w", "/static/22c146abab05f0f1e18e2f085892b2cb/5c744/popup-button-instructions-voiceover.png 1206w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Second thing VoiceOver says.`}</figcaption>
    </figure>
    <p>{`If you see what is read, you’ll notice the words “Pop up button.” Because the markup is a link, without those attributes, it would read as just a link. I honestly think that this markup could be changed to a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<button>`}</code>{` because, at the time of this reading, I cannot use the link to go to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`href`}</code>{` attribute value (/settings) and acts like a button. But the `}<strong parentName="p">{`context`}</strong>{` of knowing this is a popup may be super helpful to a screen reader.`}</p>
    <p>{`I will say, I am usually hesitant to use ARIA when I first start building things out. You may have noticed I critiqued this markup about what I would change, and it is for this reason that I try to avoid using ARIA unless it is necessary to add context. This is why in my technical posts where I am coding something from scratch, you likely won’t see ARIA attributes at first. I have quite a few reasons for that which leads me to…`}</p>
    <h2>{`What ARIA is NOT?`}</h2>
    <p>{`The first time I presented about ARIA at a meetup, a blind developer was in attendance and he grilled me. It wasn’t fun. The reason for this is because a lot of times ARIA should never be the focus of accessibility, especially nowadays that we have HTML5. HTML5 solves a lot of the semantic issues screen readers used to face.`}</p>
    <p>{`Here is what ARIA is NOT:`}</p>
    <ul>
      <li parentName="ul">{`A replacement for semantic HTML`}</li>
    </ul>
    <p>{`Hmm, that’s actually all I can think of. Let’s break this into an example to make more sense of what I mean. If you don’t like the default styling of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<button>`}</code>{`, but you like all the built-in button features, this HTML will not magically make all the button defaults work:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  Open Menu
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Let’s say you have a click event for that button. If you read my article about `}<a parentName="p" {...{
        "href": "/blog/3-simple-tips-improve-keyboard-accessibility"
      }}>{`3 Simple Tips to Improve Keyboard Accessibility`}</a>{`, you know that buttons have some default functionality built in. Without doing anything else, all this does is announce this as a button with a screen reader. However, you are unable to focus on it without a keyboard. So the next step is to make sure that we have a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tabindex`}</code>{` on it. Let’s try that.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`tabindex`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  Open Menu
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Can we now use a click event on it? Let’s try it out. Let’s update the HTML to include an ID to make it easier to target with JavaScript:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`tabindex`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  Open Menu
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Now, I’ll write some basic JavaScript here just to see if it works.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` button `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getElementById`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'button'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`alert`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'clicked!'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/fGCfnD73EyICoydoCS/giphy.gif",
        "alt": "Gif of Open Menu div being clicked with an alert, then being tabbed and receiving focus that doesn't work."
      }}></img></p>
    <p>{`As you can see from the gif, the ARIA role or tabindex didn’t fix it. We have to listen for a keypress event on the button. I’m going to debug this with you so we can walk through how much extra work this is.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'keypress'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Result of the console log:
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.37837837837837%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB7ElEQVQ4y51Ui3LbIBDU//9aO3Xs2mmapoljd+KXQOjBG3l7p4fjOGmnLjPMLQgte8tBJpWCKCTqukLTNKgqjg51Y2FpLtJYaw1bjdh0a7X2FGtsdwc8r3ZoCE9mt8iayqPcVpCFhfcJwBFt2/dj2/b92GMMuL2IidZym87vkEUdkN8LHFYVhHRwvsW1jUm5zRbfkQUilI8S5aaANrH/yIqu7Nzm334g802AeMghibAmfLnrNQoXdw+DwqcCYl1D7WsidSdP/pV0XHd7/0gKaw/xs0C5M7BE7uhg2v8kXLymLCCeBXI6lHOyv/l1vtmIp7NFT1g8CVT7Eu3xOs8uxzdMaJUlDyWkMFCF62ox2IDoIqInP0NAMAHeehhjhtrDG6WvhFTYtnSQywJ7Ku79nnx0CYnIEsfgkbxHpHIKLsBa+2GqY5xM55yyh6RDyVcl5MEgBj4YVurhHEUa+9BjJuRoaIMY04msHQi/TL4i06WmwlaQ6wovywrbLd1bSi8wEavjlAfMZCH0lZBS+wHhDJmjspFLgWJ9wIZUbl48DjvyML736JTecI/P7/RJYefhL4VSFmgUXcFKkUJNauyQoj1L1XSYo6JXiufeeHjDhNpCK36SDHJBtZjn3Q8pJVIZ/9jH76Md/Ep9+jzBbzbK3wBda+ndAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Console log of the event object, particularly showing the code is Enter",
          "title": "Console log of the event object, particularly showing the code is Enter",
          "src": "/static/2a2a4b9159b5567c48370dbdd7373668/fcda8/console-logging-keypress-event.png",
          "srcSet": ["/static/2a2a4b9159b5567c48370dbdd7373668/12f09/console-logging-keypress-event.png 148w", "/static/2a2a4b9159b5567c48370dbdd7373668/e4a3f/console-logging-keypress-event.png 295w", "/static/2a2a4b9159b5567c48370dbdd7373668/fcda8/console-logging-keypress-event.png 590w", "/static/2a2a4b9159b5567c48370dbdd7373668/efc66/console-logging-keypress-event.png 885w", "/static/2a2a4b9159b5567c48370dbdd7373668/c83ae/console-logging-keypress-event.png 1180w", "/static/2a2a4b9159b5567c48370dbdd7373668/66632/console-logging-keypress-event.png 1504w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Let’s think about what we are doing here. I am adding a keypress event on the button and checking out what properties we have in the event object. This is important because if we want to replicate a button, we have to take note of `}<strong parentName="p">{`what key`}</strong>{` we are pressing when we have the keypress event. The built-in HTML button behavior works on both keypresses Enter and Space bar. When we look at the console log, we want to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`e.code`}</code>{`. So here’s what I’ll update with a conditional.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'keypress'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`code `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Enter'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`code `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Space'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`alert`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'pressed!'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/1qj5fXKoJmeeoy9309/giphy.gif",
        "alt": "Gif of Open Menu div going into focus and getting an alert saying pressed!"
      }}></img></p>
    <p>{`So, while this isn’t the most challenging thing to do, it’s way easier to change the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<div>`}</code>{` into a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<button>`}</code>{`. You can use CSS to get rid of the default styling, and overall you’ll make your life a lot easier accessibility wise!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`The way I like to think about ARIA is `}<strong parentName="p">{`context`}</strong>{`. Sometimes you don’t need additional background - the HTML is surely enough. Sometimes it could be better though, especially if it’s a custom widget. I’ll do more about ARIA in the future regarding specific attributes and what they do - mainly because the documentation can be a bit daunting. Cheers to that!`}</p>
    <p>{`Feel free to reach out to me on `}<a parentName="p" {...{
        "href": "https://twitter.com/LittleKope/"
      }}>{`Twitter`}</a>{` if you have questions about this blog post!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      