import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Content Warning: This blog post contains gifs.`}</em></p>
    <p>{`Hey friends! Today’s writing is going to be a follow up to `}<a parentName="p" {...{
        "href": "/blog/how-accessibility-taught-better-javascript-part-one"
      }}>{`How Accessibility Taught Me to be Better At JavaScript`}</a>{`. If you have read my content, one of my favorite topics is about JavaScript and Accessibility. I speak about how JavaScript is `}<strong parentName="p">{`actually`}</strong>{` necessary to make interactive elements accessible.`}</p>
    <p>{`In my previous article, I talked about how I created a popup language menu with accessibility in mind. Making something functional and accessible was my first taste of vanilla JavaScript. The code certainly needed improvements, which we went over in the post. However, making the menu accessible started to help me understand JavaScript better.`}</p>
    <p>{`Today we are going over how I took some cringy “accordion” markup and made it accessible. Remember, an essential requirement was that I was not allowed to alter the content markup in any way. This page was a WordPress post, meaning I couldn’t go in and edit the post to be the markup I wanted it to be.`}</p>
    <h2>{`Starting out`}</h2>
    <p>{`So, this right here was the starting markup.`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Really bad markup - Accordion - Starting out" src="https://codepen.io/littlekope0903/embed/zYxRvbm?height=450&theme-id=default&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/zYxRvbm'>Really bad markup - Accordion - Starting out</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <p>{`I like clean HTML, and the inability to alter the markup got under my skin. This markup is a mess. First, it started with an unordered list, which isn’t the worst, but not ideal. Then inside the list item, it has a span for the title of the panel, an h3, another unordered list element, then a singular list item (meaning it’s not even a list?).`}</p>
    <p>{`I detest this markup so much.`}</p>
    <p>{`Now that I finished that soapbox, let’s talk about a few goals here:`}</p>
    <ul>
      <li parentName="ul">{`Hide the panels when we load the page`}</li>
      <li parentName="ul">{`Accordion panels open and close on click.`}</li>
      <li parentName="ul">{`Accordion panels open and close using the space bar or the enter key.`}</li>
      <li parentName="ul">{`Make that span `}<strong parentName="li">{`focusable`}</strong></li>
    </ul>
    <p>{`I added a little bit of SCSS to clean up the markup. I also added normalize.css in my CodePen settings.`}</p>
    <p>{`Now let’s get on to how I approached this problem 4 years ago.`}</p>
    <h2>{`How I approached the problem`}</h2>
    <p>{`As a disclaimer, this is what Lindsey 4 years ago did. There’s only one thing I `}<strong parentName="p">{`wouldn’t`}</strong>{` do; however, even so, I would add more to this code, which I do in the next section.`}</p>
    <p>{`First, let’s grab some variables:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` accordion `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getElementById`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'accordion'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Then, let’s make a conditional statement. If that accordion exists, let’s grab some other variables.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`accordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` headers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__header'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` panels `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__panel'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`I added the conditional statement because we loop through that nodeList. I don’t want to be adding event listeners on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`null`}</code></p>
    <p>{`Now let’s add the event listener`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`accordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` headers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__header'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  headers`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`forEach`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`header`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` header`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`addEventListener`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'click'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` toggleAccordion`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` panels `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__panel'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Then, let’s add that function where the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.accordion__header`}</code>{` represents `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`this`}</code>{` and the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.nextElementSibling`}</code>{` is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.accordion__panel`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`toggleAccordion`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`nextElementSibling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'visually-hidden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`If we go to the element inspector and click on the accordion item, we notice the class toggle.`}</p>
    <p><img parentName="p" {...{
        "src": "/30bbcad08ed4e16e95dbe6b1703ac9fe/toggle-class.gif",
        "alt": "Clicking on 'Question 1' and seeing the accordion__panel class has a visually-hidden class being toggled."
      }}></img></p>
    <p>{`Then let’s add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`visually-hidden`}</code>{` class in the SCSS (source: `}<a parentName="p" {...{
        "href": "https://a11yproject.com/posts/how-to-hide-content/"
      }}>{`The A11y Project`}</a>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.visually-hidden `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1px 1px 1px 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* IE6, IE7 */`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`white-space`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` nowrap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* added line */`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now let’s add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`visually-hidden`}</code>{` class to the panels, so it visually toggles.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`accordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` headers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__header'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  headers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`header`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toggleAccordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` panels `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__panel'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  panels`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`forEach`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`panel`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` panel`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`classList`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`add`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'visually-hidden'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`If you’re not thinking about accessibility, you may only add a click event and call it a day. Because these are not buttons, we have to add keypress events. We need to replicate the functionality of a button. This reason is why using semantic HTML is the best way to help accessibility.`}</p>
    <p>{`First, we have to add a tabindex of 0 to every header.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`accordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` headers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__header'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  headers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`header`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    header`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`tabIndex `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span></span>{`    header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toggleAccordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` panels `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.accordion__panel'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  panels`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`panel`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` panel`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'visually-hidden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`When we do that, we can see the focus styles whenever we press the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tab`}</code>{` key.`}</p>
    <img {...{
      "src": "/28d3c47d4feca1316bb524c1ccf0a3f5/focusable.gif",
      "alt": "Tabbing through the accordion buttons seeing the clear focus styling from the default browser settings.",
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <p>{`If we press the enter or space key, nothing happens. That’s because this isn’t a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`button`}</code>{` element with built-in `}<a parentName="p" {...{
        "href": "/blog/3-simple-tips-improve-keyboard-accessibility"
      }}>{`keyboard events`}</a>{` on click. That’s why I preach from time to time about using semantic HTML.`}</p>
    <p>{`We have to add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`keypress`}</code>{` event on the header elements.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`headers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`header`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`tabIndex `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toggleAccordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  header`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`addEventListener`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'keypress'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` toggleAccordion`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`This “works” but not quite how we want it. Because we haven’t separated which key we want to toggle the class on, it wouldn’t matter if we hit the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`k`}</code>{` key or the Space bar.`}</p>
    <p>{`So first, let’s pass the event into the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`toggleAccordion`}</code>{` function and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`console.log()`}</code>{` that`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`function`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`toggleAccordion`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`e`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  console`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`log`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`nextElementSibling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'visually-hidden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Quick interruption here. Even though I prefer buttons for this, learning how to do it the wrong way taught me a LOT about JavaScript. I learned about event handlers and the event object. As someone who was a newb to JavaScript, I learned a great deal from exploring, even if this wasn’t the best way to write the code.`}</p>
    <p>{`Back to talking about events. When we open this up in the console, we see a bunch of properties on that event.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.054054054054056%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAr0lEQVQY052QyxKDIAxF+ZUWEISA2GpbZbQP+/+/dBtZdGMXHRdnBsKdkwQxXAbka0abOsSYmABnIzxFkLV8tiAitFrDG4MQ+c0TnKOS9S6V/KnLqCoLMcaMWzwj1ARjHJSSjIZmgVJqw7bO2ZKvyl3kMGGkHtF6HOQRSipIKXcjpjDjnl688gO1CVw8/pzsX8SS3hibqQi1Nl/h2m2X8NG88EwL+mYG+a78yV7Zygd9Nq+QDpoEJAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "JavaScript Console in the Developer Tools showing the event properties for KeyPress. We see 2 properties that stick out to us. 'key' which has the value 'j' and 'code' which has the value of 'KeyJ.'",
          "title": "JavaScript Console in the Developer Tools showing the event properties for KeyPress. We see 2 properties that stick out to us. 'key' which has the value 'j' and 'code' which has the value of 'KeyJ.'",
          "src": "/static/86a3b8a6f0ed3d8103512ec1fa53648b/fcda8/keypress-event.png",
          "srcSet": ["/static/86a3b8a6f0ed3d8103512ec1fa53648b/12f09/keypress-event.png 148w", "/static/86a3b8a6f0ed3d8103512ec1fa53648b/e4a3f/keypress-event.png 295w", "/static/86a3b8a6f0ed3d8103512ec1fa53648b/fcda8/keypress-event.png 590w", "/static/86a3b8a6f0ed3d8103512ec1fa53648b/efc66/keypress-event.png 885w", "/static/86a3b8a6f0ed3d8103512ec1fa53648b/c83ae/keypress-event.png 1180w", "/static/86a3b8a6f0ed3d8103512ec1fa53648b/89557/keypress-event.png 1928w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I see a few things I can use, particularly the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`code`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`key`}</code>{`. I’m going to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`key`}</code>{` property because it’s a bit more verbose when I press the space bar.`}</p>
    <p>{`So I can do this, right?`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`toggleAccordion`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`code `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'Enter'`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`||`}</span>{` e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`code `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'Space'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`nextElementSibling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'visually-hidden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Well, no. Because this doesn’t account for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`click`}</code>{` event. Click events don’t have the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`code`}</code>{` property. What types of properties do they have that we can use to make this work for this click event? Let’s add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`console.log(e)`}</code>{` back into our function and see what we have available to us.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.2972972972973%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAACPElEQVQ4y31Ta2+jMBDkjzQiQCCxwTbv8LyQR5NrpYZc71JVp6o6qf//L0xtU3JSr7kPww5md9ZrD0ZVVWiaBlVdIU5izRXSNMFkMoHneZjNZjp2XYe6rhHHMZIkQVmWMi+FEAJFUSDPcxht20KjkahbNHWDMIxlUaKLwjDURarxer0G51yvLxYLOI4D13UvTRU3FFFIaIKWf0PGMkSiwXxOLkkKY97IR6Hx+8gN27ahUPo19uF3rHiHmBVSkGNqmXoXtu3oHM2dgV+D4XwklH6lBTu+xpJXoAsmdzIkWdMpLMuSkHFqXsS/gt6h67lIaYZduMdeHLAMcgRpDpFkWNAALExAfIaARxBxLkfzropqQXUmhV9iI7aoeIssvoUXCHiEom5XoAHXwkRGnwk4V8ZWUwxn6NhY+oUeN2Ip0qjTo1XSFrvdDlPJibxV1diRufP5/J+LUmuEkL8jV6zGKuzApC0CzkApwfnpjCiKtFWUbQYutA9V8QjGmLaX8qK+FMu2sCQFVkEnR6/gWwIvzy843Z1gmzYIHQqV91SklF64ikrQ930NwzSnyPIMp8MJ/bZHv3/E3eoeb3/esKk3cG1XC1JdPIylCpXQCNVArQVBAOPm5gbH4xGvj6/4dXfG4XaD46rB+UePbrvBxDSHs1Pn9XG7g4UGfH6XOzT1P/h4+Inf+xc83Pa4f3hG3z+h7baIslLbJkwLJMvmv5bRtlEPJUosAj4TELMQ2TyHPwv076RG+myP0TZfCb4DVIquPaoBK/AAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "JavaScript Console in the Developer Tools showing the event properties for Click. We see the 'type' property has the value of 'click.'",
          "title": "JavaScript Console in the Developer Tools showing the event properties for Click. We see the 'type' property has the value of 'click.'",
          "src": "/static/3b2eb204a4646d74eddf0d12aac23692/fcda8/click-event-type.png",
          "srcSet": ["/static/3b2eb204a4646d74eddf0d12aac23692/12f09/click-event-type.png 148w", "/static/3b2eb204a4646d74eddf0d12aac23692/e4a3f/click-event-type.png 295w", "/static/3b2eb204a4646d74eddf0d12aac23692/fcda8/click-event-type.png 590w", "/static/3b2eb204a4646d74eddf0d12aac23692/efc66/click-event-type.png 885w", "/static/3b2eb204a4646d74eddf0d12aac23692/c83ae/click-event-type.png 1180w", "/static/3b2eb204a4646d74eddf0d12aac23692/51800/click-event-type.png 1196w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`So now, I check if the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`type`}</code>{` is click or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`code`}</code>{` is a space or enter.`}</p>
    <p>{`To make this a bit easier to read, I am going to separate the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`code`}</code>{` into a ternary operator that returns true or false. I didn’t do that when I was initially doing this, but I wanted to add a little bit of readability into my conditional.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`toggleAccordion`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` pressButtonKeyCode `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`code `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'Enter'`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`||`}</span>{` e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`code `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'Space'`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`?`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`true`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`false`}</span></span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`type `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'click'`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`||`}</span>{` pressButtonKeyCode`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`nextElementSibling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'visually-hidden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And now we can click AND open with a space bar and enter key.`}</p>
    <img {...{
      "src": "/2e62e12feed382e32f9d3bd6f930fb12/working.gif",
      "alt": "Clicking on the button the corresponding accordion section is opening and closing. Then we tab to it and open and close it with our keyboard.",
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <p>{`There are a ton of things I would improve upon, which we’ll go over next. But if you want to take a look at the code, take a look at the CodePen below:`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Really bad markup - Accordion - Adding tabindex and events" src="https://codepen.io/littlekope0903/embed/gObvPwg?height=450&theme-id=default&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/gObvPwg'>Really bad markup - Accordion - Adding tabindex and events</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`What I would change now`}</h2>
    <p>{`While this `}<strong parentName="p">{`technically`}</strong>{` works, it’s not the most ideal. I had no idea what progressive enhancement was when I was learning JavaScript. I also had no idea what ARIA was.`}</p>
    <p>{`So let’s start walking through it. If you read part 1, you’ll know that I am a big fan of having a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`no-js`}</code>{` class as a way to detect if JavaScript has loaded or not.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation attr-equals"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`accordion`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation attr-equals"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`accordion no-js`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- Children elements -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Then the first thing we do when our JavaScript loaded is removing that class.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` accordion `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getElementById`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'accordion'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`accordion`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`classList`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`remove`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'no-js'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span></code></pre></div>
    <p>{`We’ll add some default styling if the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`no-js`}</code>{` class is present, meaning that JavaScript wouldn’t have loaded:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.accordion `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`.no-js `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.accordion__header `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.accordion__item `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

      `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`:first-child `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`:last-child `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.accordion__panel `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`I’ve removed the button that isn’t technically a button and had everything open by default.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.16216216216216%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAB4UlEQVQoz41S2VIbQQz0/39HLg4HhyOBolKVvCQkvk+MiQ14fUDKB/bu2uDdoaPW7NiQJx5U0mhGrZamU+HY4L5rMB+KDQxmPTl7zMWvN896/y5GaipBcStA9s1crfllifa3R0wugfEFMGlZPxbvchq3NrlR8wmjhpzbAjjrG/RzMbxfK/RyEQbFGINChNtKjLuqwbAc42/N4LZs88zxbliKNdfPR9LkSRtMrw1SpFvNhKgfhiinA5R2A1Q/hWh8Xkjsr2O9S9u74o6P3DuZ5kSm+f6AEQGF6fTKWIaVj4E+Kmz7WtQ4Wmhh/v0ctX0BkHxlL9C4kgk0zn/w9d31jxVap0thL3u8IWAvRvN4icKWr4VkS2Z8XJZGBGWzambDUEHFsm/nuPz6gO7ZSkZfAxp9xGIau3M8dyaQyxOcq3FrYXMaWT5jKIB7llXtIFQjANnSM8/x1kDSgNNwTfUDu3uO3D2LVH76KbVkX64gJ6OQUUlGfb5H9zHn8klkpu+SVdz8XGHmJTtUhmnLqH5kf5sjkQl3qXvctizdFMyX0sFaCQScdBIdumKyap4sVFvUWi8bqd6831GSM3qmNq0uRYcFe0ctT64ShqNz0dEfKj/Rk8TTNnDf2XgXv7DOf3ci7H/PaF/9JlPD0QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The rendered HTML in the browser with the no-js class. There are no more buttons and just the header and the text body, neither of which are hidden",
          "title": "The rendered HTML in the browser with the no-js class. There are no more buttons and just the header and the text body, neither of which are hidden",
          "src": "/static/c7d48a5ce5c32249346d0ec1aa6c6443/fcda8/no-js.png",
          "srcSet": ["/static/c7d48a5ce5c32249346d0ec1aa6c6443/12f09/no-js.png 148w", "/static/c7d48a5ce5c32249346d0ec1aa6c6443/e4a3f/no-js.png 295w", "/static/c7d48a5ce5c32249346d0ec1aa6c6443/fcda8/no-js.png 590w", "/static/c7d48a5ce5c32249346d0ec1aa6c6443/efc66/no-js.png 885w", "/static/c7d48a5ce5c32249346d0ec1aa6c6443/914c7/no-js.png 978w"],
          "sizes": "(max-width: 590px) 100vw, 590px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now, back into the JavaScript. On the headers, we want to set the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{` attribute to false and give it a role of button.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`headers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`header`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`tabIndex `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  header`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'role'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'button'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  header`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'aria-expanded'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toggleAccordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'keypress'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toggleAccordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`While we are setting roles, I am going to set the panels’ role to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`region`}</code></p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`accordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// header code`}</span>{`
  panels`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`panel`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    panel`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'role'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'region'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Next, I’ll toggle aria-expanded and remove the toggling of the class in the function. As a note, even though we set the attribute to be a boolean, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getAttribute()`}</code>{` returns a string.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`toggleAccordion`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` pressButtonKeyCode `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
    e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`code `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Enter'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`code `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Space'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` ariaExpanded `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`getAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'aria-expanded'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` pressButtonKeyCode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`ariaExpanded `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'false'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'aria-expanded'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`else`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'aria-expanded'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We don’t need to visually hide the content because we have the button that controls the information. It’s not a good screen reader user experience to read the information they didn’t want. I love using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-expanded`}</code>{` in CSS to toggle between `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`display: none`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`display: block`}</code>{` for the panel.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.accordion `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`__header `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// more scss`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token selector"
            }}><span parentName="span" {...{
                "className": "token parent important"
              }}>{`&`}</span>{`[aria-expanded='true'] + .accordion__panel `}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` block`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`__panel `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` none`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px solid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`h3 `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`I’m going to be adding a few ARIA attribute to help associate the header and the panel together.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions"
        }}>{`aria-controls`}</a>{` - This can be confusing for some people. I highly recommend reading `}<a parentName="li" {...{
          "href": "https://tink.uk/using-the-aria-controls-attribute/"
        }}>{`Léonie’s post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-labelledby_attribute"
        }}>{`aria-labelledby`}</a></li>
    </ul>
    <p>{`I based this on the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html"
      }}>{`WAI-ARIA authoring practices`}</a>{`.`}</p>
    <p>{`First, the headers:`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`headers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`header`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`tabIndex `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'role'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'button'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`// This will match the aria-labelledby on the panel`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  header`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'id'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`accordion-header-`}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span>{`i `}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`+`}</span>{` `}<span parentName="span" {...{
                  "className": "token number"
                }}>{`1`}</span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'aria-expanded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`// This will match the id on the panel`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  header`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'aria-controls'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`accordion-section-`}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span>{`i `}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`+`}</span>{` `}<span parentName="span" {...{
                  "className": "token number"
                }}>{`1`}</span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toggleAccordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'keypress'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` toggleAccordion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Then we’ll take those and make sure they match up accurately with the panels`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`panels`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`panel`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`// This will match the aria-controls on the header`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  panel`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'id'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`accordion-section-`}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span>{`i`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`+`}</span><span parentName="span" {...{
                  "className": "token number"
                }}>{`1`}</span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`  panel`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'role'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'region'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token comment"
            }}>{`// This will match the id on the header`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  panel`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setAttribute`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'aria-labelledby'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`accordion-header-`}</span><span parentName="span" {...{
                "className": "token interpolation"
              }}><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`\${`}</span>{`i`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`+`}</span><span parentName="span" {...{
                  "className": "token number"
                }}>{`1`}</span><span parentName="span" {...{
                  "className": "token interpolation-punctuation punctuation"
                }}>{`}`}</span></span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now let’s hear this on a screenreader and hear the final verdict.`}</p>
    <video controls height="500" style={{
      "margin": "1rem auto"
    }}>
  <source src="/d1fd2afc80c49a7646bd1900d52da7d1/final-screenreader.mov" type="video/mp4" />
    </video>
    <p>{`If you want to play around with the code, fork the CodePen and check it out.`}</p>
    <iframe height="450" style={{
      "width": "100%"
    }} scrolling="no" title="Really bad markup - Accordion - Progressive Enhancement" src="https://codepen.io/littlekope0903/embed/oNgExXZ?height=450&theme-id=default&default-tab=html,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/littlekope0903/pen/oNgExXZ'>Really bad markup - Accordion - Progressive Enhancement</a> by Lindsey Kopacz
  (<a href='https://codepen.io/littlekope0903'>@littlekope0903</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`Conclusion`}</h2>
    <p>{`Was this the most ideal markup ever? No. Did this teach me a lot about JavaScript? Yes. Did this teach me the value of using buttons where I have keyboard events built-in? Yes.`}</p>
    <p>{`Cheers! Have a great week!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      